import { Label, DateField, RadioField } from "@clevero/components";

const SchoolStatus = ({ formValue, changeHandler, theme, selectData }) => {
  const HighestSchoolLevels = selectData;
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>AT SCHOOL STATUS</h4>
        <p>
          Determine whether or not you are currently attending high school or
          any other secondary school level education.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label
            label="School Completion Date"
            name="SchoolCompletionDate"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <DateField
            name="school-completion-date"
            value={formValue["school-completion-date"]}
            onChange={changeHandler}
          />
        </div>
        <div className="acfe-form-field">
          <Label
            label="What is the highest school level completed?"
            name="HighestSchoolLevel"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <RadioField
            name="highest-school-level-completed-identifier"
            value={
              formValue["highest-school-level-completed-identifier"] &&
              +JSON.parse(
                formValue["highest-school-level-completed-identifier"]
              )[0]
            }
            options={
              HighestSchoolLevels && HighestSchoolLevels.length !== 0
                ? HighestSchoolLevels.map((HighestSchoolLevel) => ({
                    value: HighestSchoolLevel.value,
                    label: HighestSchoolLevel.label,
                  }))
                : null
            }
            onChange={changeHandler}
            orientation="vertical"
          />
        </div>
      </div>
    </section>
  );
};

export default SchoolStatus;
