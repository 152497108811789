import { Label, TextField, DropdownField } from "@clevero/components";

const Identity = ({
  formValue,
  changeHandler,
  requiredFormValue,
  requiredChangeHandler,
  formError,
  theme,
  selectData,
}) => {
  const Titles = selectData;
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>IDENTITY</h4>
        <p>
          <em style={{ color: theme.primaryColor }}>First name</em> and{" "}
          <em style={{ color: theme.primaryColor }}>last name</em> are required
          fields used for reporting.{" "}
          <em style={{ color: theme.primaryColor }}>Title</em> and{" "}
          <em style={{ color: theme.primaryColor }}>preferred name</em> are
          optional fields.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label label="Title" name="Title" />
          <DropdownField
            name="client-title"
            value={
              formValue["client-title"] &&
              +JSON.parse(formValue["client-title"])[0]
            }
            onChange={changeHandler}
            isLoading={!(Titles && Titles.length !== 0)}
            options={
              Titles && Titles.length !== 0
                ? Titles.map((Title) => ({
                    value: Title.value,
                    label: Title.label,
                  }))
                : []
            }
            placeholder={"Select option"}
          />
        </div>
        <div className="acfe-form-field">
          <Label mandatory label="First Name" name="FirstName" />
          <TextField
            name="client-first-given-name"
            value={requiredFormValue["client-first-given-name"]}
            onChange={requiredChangeHandler}
            error={formError["client-first-given-name"]}
          />
        </div>
        <div className="acfe-form-field">
          <Label mandatory label="Last Name" name="LastName" />
          <TextField
            name="client-family-name"
            value={requiredFormValue["client-family-name"]}
            onChange={requiredChangeHandler}
            error={formError["client-family-name"]}
          />
        </div>
        <div className="acfe-form-field">
          <Label label="Preferred Name" name="PreferredName" />
          <TextField
            name="preferred-name"
            value={formValue["preferred-name"]}
            onChange={changeHandler}
          />
        </div>
      </div>
    </section>
  );
};

export default Identity;
