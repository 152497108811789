import { useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@clevero/components";
import { Global } from "../../context/GlobalProvider";

const Error = () => {
  const { instanceId } = useContext(Global);

  const location = useLocation();
  const history = useHistory();

  const errorVariants = {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    visible: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
  };

  return (
    <motion.div
      variants={errorVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <center style={{ padding: "10px" }}>
        <h2 className="sub-heading">
          {(location.state && location.state.message) ||
            "An error occured. Sorry about that."}
        </h2>

        <div style={{ display: "flex", gap: "10px", width: "fit-content" }}>
          {location.state?.allowBack && (
            <Button onClick={() => history.goBack(-1)}>Back to Payment</Button>
          )}

          <Button
            onClick={() => history.push({ pathname: `/home/${instanceId}` })}
          >
            Go back to Home
          </Button>
        </div>
      </center>
    </motion.div>
  );
};

export default Error;
