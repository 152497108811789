import { useContext } from "react";

import format from "date-fns/format";

import { Button } from "@clevero/components";
import {
  FaChalkboardTeacher,
  FaHourglassEnd,
  FaHourglassStart,
  FaMapMarkerAlt,
  FaRegCalendarCheck,
} from "react-icons/fa";

import { Theme } from "../../context/ThemeProvider";
import { HTMLresolver } from "../../Utils/HTMLresolver";
import "../../styles/EntryDetails.css";

const SessionDetails = ({ Session, Register, SetRegister }) => {
  const { theme } = useContext(Theme);

  const toggleRegister = () => {
    SetRegister(true);
  };

  // const currentWindowHref = window.location.href;
  // const currentWindowProtocol = window.location.protocol.replace(":", "");

  return (
    <div className="entry-details-container">
      <h1
        className="entry-details-title"
        data-free={
          (Session["free-session"] === "true" || !Session["activity-fee"]) &&
          "true"
        }
      >
        {Session["display-name"] || Session["name"]}
      </h1>

      <div
        className="entry-long-description"
        dangerouslySetInnerHTML={HTMLresolver(
          Session["long-description"],
          "No Description Available"
        )}
      />

      <div className="entry-info-container">
        <div className="entry-info-wrapper">
          <span
            className="entry-info-header"
            style={{ color: theme.secondaryColor }}
          >
            <FaRegCalendarCheck size={".875rem"} title={"Event-date"} />
            <h4>Date</h4>
          </span>
          <p className="entry-info">
            {Session["start-time"]
              ? format(new Date(Session["start-time"]), "do MMM yyyy, eeee")
              : "N/A"}
          </p>
        </div>

        <div className="entry-info-wrapper">
          <span
            className="entry-info-header"
            style={{ color: theme.secondaryColor }}
          >
            <FaMapMarkerAlt size={".875rem"} title={"Where"} />
            <h4>Where</h4>
          </span>
          <p className="entry-info">{Session["location-name"] || "N/A"}</p>
        </div>

        <div className="entry-info-wrapper">
          <span
            className="entry-info-header"
            style={{ color: theme.secondaryColor }}
          >
            <FaHourglassStart size={".875rem"} title={"Event-start"} />
            <h4>Start</h4>
          </span>
          <p className="entry-info">
            {Session["start-time"]
              ? format(new Date(Session["start-time"]), "p")
              : "N/A"}
          </p>
        </div>

        <div className="entry-info-wrapper">
          <span
            className="entry-info-header"
            style={{ color: theme.secondaryColor }}
          >
            <FaHourglassEnd size={".875rem"} title={"Event-end"} />
            <h4>End</h4>
          </span>
          <p className="entry-info">
            {Session["end-time"]
              ? format(new Date(Session["end-time"]), "p")
              : "N/A"}
          </p>
        </div>

        <div className="entry-info-wrapper">
          <span
            className="entry-info-header"
            style={{ color: theme.secondaryColor }}
          >
            <FaChalkboardTeacher size={".875rem"} title={"Instructor"} />
            <h4>Facilitator</h4>
          </span>
          <p className="entry-info">{Session.instructor || "N/A"}</p>
        </div>
      </div>

      {Session["activity-fee"] && (
        <div className="cost-info">
          <div
            className="cost-info-header"
            style={{ color: theme.secondaryColor }}
          >
            Cost($)
          </div>
          <p>{"$" + Session["activity-fee"]}</p>
        </div>
      )}

      <div className="btn-group">
        <Button
          className="register-btn"
          onClick={toggleRegister}
          disabled={Register}
          size={"md"}
        >
          {!Register ? "Register" : "Fill in the form to register"}
        </Button>
      </div>

      {/* Facebook Sharing
      <iframe
        src={`https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2F${
          currentWindowProtocol === "https"
            ? currentWindowHref.replace(/https?:\/\//i, "")
            : currentWindowHref.replace(/http?:\/\//i, "")
        }%2Fdocs%2Fplugins%2F&layout=button&size=large&width=77&height=28&appId`}
        width="77"
        height="28"
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe> */}
    </div>
  );
};

export default SessionDetails;
