import ReactDom from "react-dom";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";
import "../styles/Modal.css";

const Backdrop = ({ children, onClick }) => (
  <motion.div
    className="modal-backdrop"
    onClick={onClick}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    {children}
  </motion.div>
);

const Modal = ({ children, handleClose }) => {
  const dropIn = {
    hidden: { y: "100vh", opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: "100vh", opacity: 0 },
  };
  return ReactDom.createPortal(
    <Backdrop onClick={handleClose}>
      <motion.div
        className="modal"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <IoClose
          className="modal-close-btn"
          onClick={handleClose}
          title="close"
        />
        {children}
      </motion.div>
    </Backdrop>,
    document.getElementById("nh-portal")
  );
};

export default Modal;
