import { motion } from "framer-motion";
import "../../styles/DetailsMain.css";

const SessionPageWrapper = (props) => {
  const containerVariants = {
    hidden: {
      y: "100vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };
  return (
    <motion.main
      className="details-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {props.children}
    </motion.main>
  );
};

export default SessionPageWrapper;
