import { Label, RadioField } from "@clevero/components";

const ResidencyStatus = ({ formValue, changeHandler, theme, selectData }) => {
  const ResidencyStatuses = selectData;
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>RESIDENCY STATUS</h4>
        <p>
          To satisfy the condition of citizenship you must hold either an
          Australian or New Zealand citizenship. Permanent residency or
          Humanitarian visa is also accepted.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label
            label="What is your current residency status?"
            name="CurrentResidencyStatus"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <RadioField
            name="current-residency-status"
            value={
              formValue["current-residency-status"] &&
              +JSON.parse(formValue["current-residency-status"])[0]
            }
            options={
              ResidencyStatuses && ResidencyStatuses.length !== 0
                ? ResidencyStatuses.map((ResidencyStatus) => ({
                    value: ResidencyStatus.value,
                    label: ResidencyStatus.label,
                  }))
                : null
            }
            onChange={changeHandler}
            orientation="vertical"
          />
        </div>
      </div>
    </section>
  );
};

export default ResidencyStatus;
