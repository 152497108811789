import { Label, NumberField } from "@clevero/components";

const VSN = ({ formValue, changeHandler, theme }) => {
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>VICTORIAN STUDENT NUMBER</h4>
        <p>
          The{" "}
          <em style={{ color: theme.primaryColor }}>
            Victorian student number
          </em>{" "}
          (VSN) is a unique student ID for all Victorian students in school or
          vocational training from preparatory school to age 25.
        </p>
        <p>
          If you are over 25 years of age you do not need to provide a{" "}
          <em style={{ color: theme.primaryColor }}>
            Victorian student number
          </em>
          .
        </p>
        <p>
          If you are 25 years old or younger and are unsure of your{" "}
          <em style={{ color: theme.primaryColor }}>
            Victorian student number
          </em>{" "}
          or do not have a{" "}
          <em style={{ color: theme.primaryColor }}>
            Victorian student number
          </em>{" "}
          then leave empty.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label
            label="Victorian Student Number (VSN)"
            name="VSN"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <NumberField
            name="victorian-student-number-vsn"
            value={formValue["victorian-student-number-vsn"]}
            onChange={changeHandler}
            hideControls
          />
        </div>
      </div>
    </section>
  );
};

export default VSN;
