import { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import { motion } from "framer-motion";
import {
  Button,
  HorizontalInlineLoader,
  Tile,
  TileBody,
} from "@clevero/components";
import { FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";

import { Theme } from "../../context/ThemeProvider";
import { HTMLresolver } from "../../Utils/HTMLresolver";
import "../../styles/Card.css";

const CourseCard = ({ Course, cardVariants }) => {
  const { instance_identifier } = useParams();
  const history = useHistory();
  const { theme } = useContext(Theme);

  const [imgLoaded, setImgLoaded] = useState(false);

  const clickHandler = () => {
    history.push({
      pathname: `/${instance_identifier}/course/${Course.id}`,
    });
  };
  return (
    <motion.article className="entry-card" variants={cardVariants}>
      <Tile className="entry-tile" hover>
        <section className="divider">
          <div className="img-container">
            <span
              className="category"
              style={{
                backgroundColor: `${theme.primaryColor}`,
                color: `${theme.primaryTextColor}`,
              }}
            >
              {Course["activity-category"]}
            </span>

            <img
              className="website-image"
              src={
                Course["website-image"]
                  ? Course["website-image"]
                  : `https://s3.ap-southeast-2.amazonaws.com/image-uploads.clevero.co/NH_placeholder_image.png`
              }
              alt={"could not load image of " + Course.name}
              style={{ display: imgLoaded ? "inline-block" : "none" }}
              onLoad={() => setImgLoaded(!imgLoaded)}
              onClick={clickHandler}
            />

            <div className="loader-container">
              <HorizontalInlineLoader
                className="horizontal-loader"
                style={{
                  display: !imgLoaded ? "inline-flex" : "none",
                }}
              />
            </div>
          </div>

          <TileBody className="entry-tile-body">
            {/* course title */}
            <div className="title">{Course.name}</div>

            {/* course description */}
            <div
              className="description"
              dangerouslySetInnerHTML={HTMLresolver(
                Course["short-description"],
                "No Description Available"
              )}
            ></div>

            {/* course when */}
            {Course.when && (
              <div className="info-container">
                <FaCalendarAlt style={{ color: theme.secondaryColor }} />
                &nbsp;{Course.when}
              </div>
            )}

            {/* course where */}
            {Course.where && (
              <div className="info-container">
                <FaMapMarkerAlt style={{ color: theme.secondaryColor }} />
                &nbsp;{Course.where}
              </div>
            )}

            {/* course cost */}
            {Course.cost && (
              <div className="info-container">
                <div style={{ color: theme.secondaryColor, fontWeight: "600" }}>
                  Cost($)
                </div>
                &nbsp;
                <span className="cost">{Course.cost}</span>
              </div>
            )}

            {/* register button */}
            <Button className="register-btn" size={"md"} onClick={clickHandler}>
              Register
            </Button>
          </TileBody>
        </section>
      </Tile>
    </motion.article>
  );
};

export default CourseCard;
