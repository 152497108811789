import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@clevero/components";
import { Theme } from "../../context/ThemeProvider";
import format from "date-fns/format";
import {
  FaRegCalendarCheck,
  FaRegCalendarTimes,
  FaMapMarkerAlt,
  FaChalkboardTeacher,
} from "react-icons/fa";
import { HTMLresolver } from "../../Utils/HTMLresolver";
import PricingTable from "./PricingTable";
import "../../styles/EntryDetails.css";

const CourseDetails = ({
  EntryPricing: { coursePricing },
  Course,
  Register,
  SetRegister,
  InstanceId,
}) => {
  const { theme } = useContext(Theme);

  const history = useHistory();

  // const currentWindowHref = window.location.href;
  //const currentWindowProtocol = window.location.protocol.replace(":", "");

  const toggleRegister = () => {
    //No pricing lvl === course is free
    if (
      Course["free-course"] === "true" ||
      !coursePricing.length ||
      (Course.isAcfe === "true" && !coursePricing.length) //possible bug case
    ) {
      SetRegister(true);
    } else if (Course.isAcfe === "true") {
      //isAcfe flag is not boolean
      history.push({
        pathname: `/${InstanceId}/acfe-form`,
        state: {
          entry: Course,
          coursePricing:
            coursePricing &&
            Array.isArray(coursePricing) &&
            coursePricing.length > 0
              ? coursePricing
              : Course.cost,
        },
      });
    } else {
      SetRegister(true);
    }
  };

  return (
    <div className="entry-details-container">
      <h1
        className="entry-details-title"
        data-free={
          (Course["free-course"] === "true" || !coursePricing.length) && "true"
        }
      >
        {Course.name}
      </h1>

      <div
        className="entry-long-description"
        dangerouslySetInnerHTML={HTMLresolver(
          Course["long-description"],
          "No Description Available"
        )}
      />

      <div className="entry-info-container">
        <div className="entry-info-wrapper">
          <span
            className="entry-info-header"
            style={{ color: theme.secondaryColor }}
          >
            <FaRegCalendarCheck size={".875rem"} title={"Start-date"} />
            <h4>Starts</h4>
          </span>
          <p className="entry-info">
            {Course["start-date"]
              ? format(new Date(Course["start-date"]), "do MMM yyyy, eeee")
              : "N/A"}
          </p>
        </div>

        <div className="entry-info-wrapper">
          <span
            className="entry-info-header"
            style={{ color: theme.secondaryColor }}
          >
            <FaRegCalendarTimes size={".875rem"} title={"End-date"} />
            <h4>Ends</h4>
          </span>
          <p className="entry-info">
            {Course["end-date"]
              ? format(new Date(Course["end-date"]), "do MMM yyyy, eeee")
              : "N/A"}
          </p>
        </div>

        <div className="entry-info-wrapper">
          <span
            className="entry-info-header"
            style={{ color: theme.secondaryColor }}
          >
            <FaMapMarkerAlt size={".875rem"} title={"Where"} />
            <h4>Where</h4>
          </span>
          <p className="entry-info">{Course.where || "N/A"}</p>
        </div>

        <div className="entry-info-wrapper">
          <span
            className="entry-info-header"
            style={{ color: theme.secondaryColor }}
          >
            <FaChalkboardTeacher size={".875rem"} title={"Instructor"} />
            <h4>Facilitator</h4>
          </span>
          <p className="entry-info">{Course.instructor || "N/A"}</p>
        </div>
      </div>

      {Course["free-course"] === "true"
        ? null
        : !!coursePricing.length && (
            <PricingTable
              coursePricing={coursePricing}
              theme={theme}
              sessionRegistration={Course.sessionRegistration}
            />
          )}

      {Course.sessionRegistration === "true" &&
      Course.disallowBulkRegistration === "true" ? null : (
        <div className="btn-group">
          <Button
            className="register-btn"
            onClick={toggleRegister}
            disabled={
              Register ||
              (Course.sessionRegistration === "false" &&
                !!Course["remaining-capacity"] &&
                Course["remaining-capacity"] <= 0)
            }
            size={"md"}
          >
            {!Register
              ? Course.sessionRegistration === "true"
                ? "Register for all Sessions"
                : !!Course["remaining-capacity"] &&
                  Course["remaining-capacity"] <= 0
                ? "Sold Out"
                : "Register for Course/Program"
              : Course.isAcfe === "true" && !coursePricing.length
              ? "Error: Cannot proceed to registration"
              : "Fill in the form to register"}
          </Button>

          {Course.sessionRegistration === "true" && (
            <div className="btn-details">
              <i>or register for individual session below</i>
            </div>
          )}
        </div>
      )}

      {/* <iframe
        src={`https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2F${
          currentWindowProtocol === "https"
            ? currentWindowHref.replace(/https?:\/\//i, "")
            : currentWindowHref.replace(/http?:\/\//i, "")
        }%2Fdocs%2Fplugins%2F&layout=button&size=large&width=77&height=28&appId`}
        width="77"
        height="28"
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe> */}
    </div>
  );
};

export default CourseDetails;
