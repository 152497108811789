import React, { useState, createContext } from "react";

export const Global = createContext();

const GlobalProvider = (props) => {
  const [entries, setEntries] = useState(null);
  const [instanceId, setInstanceId] = useState(null);
  const [categories, setCategories] = useState(null);
  return (
    <Global.Provider
      value={{
        entries,
        setEntries,
        instanceId,
        setInstanceId,
        categories,
        setCategories,
      }}
    >
      {props.children}
    </Global.Provider>
  );
};

export default GlobalProvider;
