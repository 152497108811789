import {
  DateField,
  Label,
  RadioField,
  CheckboxField,
} from "@clevero/components";
import { useEffect } from "react";

const ConcessionEntitlement = ({
  formValue,
  checkboxValue,
  setCheckboxValue,
  changeHandler,
  theme,
  selectData,
}) => {
  const ConcessionTypes = selectData;

  useEffect(() => {
    if (!checkboxValue.ConcessionEntitlement) {
      changeHandler({ name: "ConcessionExpiryDate", value: "" });
      changeHandler({ name: "ConcessionType", value: "" });
    }
  }, [checkboxValue.ConcessionEntitlement, changeHandler]);
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>CONCESSION ENTITLEMENT</h4>
        <p>
          A{" "}
          <em style={{ color: theme.secondaryColor }}>
            Centrelink Health Care
          </em>{" "}
          (HCC) or{" "}
          <em style={{ color: theme.secondaryColor }}>
            Pensioner Concession Card
          </em>{" "}
          (PCC) is required to claim the concession fee.
        </p>
        <p>
          You MUST present this to one of our offices to confirm eligibility.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <CheckboxField
            label="I have a health care card or pensioner concession card"
            name="ConcessionEntitlement"
            value={checkboxValue.ConcessionEntitlement}
            onChange={() => {
              setCheckboxValue({
                ...checkboxValue,
                ConcessionEntitlement: !checkboxValue.ConcessionEntitlement,
              });
            }}
          />
        </div>
        {checkboxValue.ConcessionEntitlement && (
          <>
            <div className="acfe-form-field">
              <Label label="Concession Type" name="ConcessionType" />
              <RadioField
                name="concession-type"
                value={
                  formValue["concession-type"] &&
                  +JSON.parse(formValue["concession-type"])[0]
                }
                onChange={changeHandler}
                options={
                  ConcessionTypes && ConcessionTypes.length !== 0
                    ? ConcessionTypes.map((ConcessionType) => ({
                        value: ConcessionType.value,
                        label: ConcessionType.label,
                      }))
                    : null
                }
              />
            </div>
            <div className="acfe-form-field">
              <Label
                label="Concession Expiry Date"
                name="ConcessionExpiryDate"
              />
              <DateField
                name="concession-expiry-date"
                value={formValue["concession-expiry-date"]}
                onChange={changeHandler}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default ConcessionEntitlement;
