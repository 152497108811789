import { useContext } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "@clevero/components";
import { Global } from "../../context/GlobalProvider";

const Success = () => {
  const { instanceId } = useContext(Global);
  const location = useLocation();
  const instance_identifier =
    instanceId || (location.state && location.state.instance_identifier);

  const successVariants = {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    visible: { scale: 1, opacity: 1 },
    exit: { scale: 0, opacity: 0 },
  };

  return (
    <motion.div
      variants={successVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <center style={{ padding: "10px" }}>
        <h2 className="sub-heading">
          Your registration has been successfully received.
        </h2>
        <Link
          to={{
            pathname: `/home/${instance_identifier}`,
          }}
        >
          <Button>Go back to Home</Button>
        </Link>
      </center>
    </motion.div>
  );
};

export default Success;
