import { Label, TextField } from "@clevero/components";

const PrimaryLanguage = ({
  requiredFormValue,
  requiredChangeHandler,
  formError,
  theme,
}) => {
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>PRIMARY LANGUAGE</h4>
        <p>
          Your <em style={{ color: theme.primaryColor }}>primary language</em>{" "}
          is the language you speak most fluently or most often.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label
            mandatory
            label="What is the primary language that you speak at home?"
            name="primary-language"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <TextField
            name="primary-language"
            value={requiredFormValue["primary-language"]}
            onChange={requiredChangeHandler}
            error={formError["primary-language"]}
          />
        </div>
      </div>
    </section>
  );
};

export default PrimaryLanguage;
