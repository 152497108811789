import { Label, DropdownField } from "@clevero/components";

const EducationalAchievement = ({
  formValue,
  changeHandler,
  requiredFormValue,
  requiredChangeHandler,
  formError,
  theme,
  selectData1,
  selectData2,
}) => {
  const EducationalAchievements = selectData1;
  const StudyReasons = selectData2;
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>
          PRIOR EDUCATIONAL ACHIEVEMENT
        </h4>
        <p>
          Indicates whether or not a student has completed one or more types of
          post-secondary school qualifications before the current enrolment.
        </p>
        <p>
          <em style={{ color: theme.primaryColor }}>
            Australian qualification
          </em>{" "}
          is a qualification obtained from a recognised Australian tertiary
          school, TAFE, or educational organisation.
        </p>
        <p>
          <em style={{ color: theme.primaryColor }}>
            Australian equivalent qualification
          </em>{" "}
          is an international qualification that has gone through the process of
          being assessed by the relevant government authority in Australia.
        </p>
        <p>
          <em style={{ color: theme.primaryColor }}>
            International qualification
          </em>{" "}
          is a qualification obtained overseas that has not been assessed by the
          relevant government authority in Australia.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label
            label="Australian Qualification"
            name="AustralianQualification"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <DropdownField
            name="australian-qualification"
            value={
              formValue["australian-qualification"] &&
              +JSON.parse(formValue["australian-qualification"])[0]
            }
            onChange={changeHandler}
            isLoading={
              !(EducationalAchievements && EducationalAchievements.length !== 0)
            }
            options={
              EducationalAchievements && EducationalAchievements.length !== 0
                ? EducationalAchievements.map((EducationalAchievement) => ({
                    value: EducationalAchievement.value,
                    label: EducationalAchievement.label,
                  }))
                : null
            }
            placeholder={"Select option"}
          />
        </div>
        <div className="acfe-form-field">
          <Label
            label="Australian Equivalent"
            name="AustralianEquivalent"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <DropdownField
            name="australian-equivalent"
            value={
              formValue["australian-equivalent"] &&
              +JSON.parse(formValue["australian-equivalent"])[0]
            }
            onChange={changeHandler}
            isLoading={
              !(EducationalAchievements && EducationalAchievements.length !== 0)
            }
            options={
              EducationalAchievements && EducationalAchievements.length !== 0
                ? EducationalAchievements.map((EducationalAchievement) => ({
                    value: EducationalAchievement.value,
                    label: EducationalAchievement.label,
                  }))
                : null
            }
            placeholder={"Select option"}
          />
        </div>
        <div className="acfe-form-field">
          <Label
            label="International Qualification"
            name="InternationalQualification"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <DropdownField
            name="international-qualification"
            value={
              formValue["international-qualification"] &&
              +JSON.parse(formValue["international-qualification"])[0]
            }
            onChange={changeHandler}
            isLoading={
              !(EducationalAchievements && EducationalAchievements.length !== 0)
            }
            options={
              EducationalAchievements && EducationalAchievements.length !== 0
                ? EducationalAchievements.map((EducationalAchievement) => ({
                    value: EducationalAchievement.value,
                    label: EducationalAchievement.label,
                  }))
                : null
            }
            placeholder={"Select option"}
          />
        </div>
        <div className="acfe-form-field">
          <Label
            mandatory
            label="Study Reason"
            name="StudyReason"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <DropdownField
            name="study-reason"
            value={
              requiredFormValue["study-reason"] &&
              +JSON.parse(requiredFormValue["study-reason"])[0]
            }
            onChange={requiredChangeHandler}
            isLoading={!(StudyReasons && StudyReasons.length !== 0)}
            options={
              StudyReasons && StudyReasons.length !== 0
                ? StudyReasons.map((StudyReason) => ({
                    value: StudyReason.value,
                    label: StudyReason.label,
                  }))
                : null
            }
            error={formError["study-reason"]}
            placeholder={"Select option"}
          />
        </div>
      </div>
    </section>
  );
};

export default EducationalAchievement;
