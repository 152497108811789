const PricingTable = (props) => {
  return (
    <table className="cost-table">
      <thead>
        <tr
          style={{
            backgroundColor: props.theme.primaryColor,
            color: props.theme.primaryTextColor,
          }}
        >
          <th>Price level</th>
          {(props.sessionRegistration === "true" && (
            <>
              <th>Per Session</th>
              <th>All Sessions</th>
            </>
          )) || <th>Cost</th>}
        </tr>
      </thead>
      <tbody>
        {props.coursePricing.map((price, i) => (
          <tr key={i}>
            <td>{price["price-level"]}</td>
            {(props.sessionRegistration === "true" && (
              <>
                <td>
                  {price["session-amount"]
                    ? `$${price["session-amount"]}`
                    : "-"}
                </td>
                <td>{price.amount ? `$${price.amount}` : "-"}</td>
              </>
            )) || <td>{price.amount ? `$${price.amount}` : "-"}</td>}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PricingTable;
