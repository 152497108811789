import axios from "axios";
import qs from "query-string";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? `http://localhost:8081/api/ext/nh-houses`
    : process.env.REACT_APP_API_URL;

export const getAPI = async ({
  categoryId,
  instanceId,
  categoryPreFilterId,
}) => {
  // take instanceIdentifier as arguement here
  let res;
  try {
    if (categoryId) {
      // since categoryId is an object: {'0': [id], '1': [id]}
      var categoryQS = Object.values(categoryId)
        .map((id) => "category=" + id)
        .join("&");

      res = await axios.get(
        `${BASE_URL}?instanceIdentifier=${instanceId}&${categoryQS}`
      );
    } else if (categoryPreFilterId) {
      res = await axios.get(
        `${BASE_URL}?instanceIdentifier=${instanceId}&category=${categoryPreFilterId}`
      );
    } else
      res = await axios.get(`${BASE_URL}?instanceIdentifier=${instanceId}`);

    const entries = {
      courseEntries: res.data.courseEntries,
      sessionEntries: res.data.sessionEntries,
    };
    return entries;
  } catch (err) {
    throw err;
  }
};

export const getCategories = async ({ instanceId }) => {
  try {
    const {
      data: { categories },
    } = await axios.get(
      `${BASE_URL}/categories?instanceIdentifier=${instanceId}`
    );
    return categories;
  } catch (err) {
    throw err;
  }
};

export const getEntry = async ({ entryId, instanceId, type }) => {
  try {
    const { data: entry } = await axios.get(
      `${BASE_URL}/${
        type === "course" ? `course/` : type === "session" ? `session/` : null
      }${entryId}?instanceIdentifier=${instanceId}`
    );
    return entry && entry.entry;
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const updateURL = async ({ entryId, instanceId, type, URL }) => {
  try {
    const { data: updateFlag } = await axios.put(
      `${BASE_URL}/${
        type === "course" ? `course/` : type === "session" ? `session/` : null
      }${entryId}?instanceIdentifier=${instanceId}`,
      { URL }
    );
    return updateFlag;
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const getSubRecordEntry = async ({ entryId, instanceId }) => {
  try {
    const query = { entryId, instanceIdentifier: instanceId };
    const queryString = qs.stringify(query);

    const { data: coursePricing } = await axios.get(
      `${BASE_URL}/get-course-pricing?${queryString}`
    );

    const sortedCoursePricing = coursePricing.coursePricing.sort(function (
      a,
      b
    ) {
      return +a.amount - +b.amount;
    });

    return { coursePricing: sortedCoursePricing };
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const getSessionsOfCourse = async ({ courseId, instanceId }) => {
  try {
    const query = { courseId, instanceIdentifier: instanceId };
    const queryString = qs.stringify(query);

    const { data: sessionEntries } = await axios.get(
      `${BASE_URL}/get-sessions-of-course?${queryString}`
    );
    return sessionEntries.sessions;
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const registerMember = async ({
  instanceId,
  formData,
  memberForm = false,
}) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/register-member?instanceIdentifier=${instanceId}&memberExists=${memberForm}`,
      { formData }
    );
    return res.data;
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const registerAcfeEntry = async ({ instanceId, formData }) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/register-afce-member?instanceIdentifier=${instanceId}`,
      { formData }
    );
    return res.data;
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const getSelectData = async ({ instanceId, data }) => {
  try {
    const {
      data: { selectValues },
    } = await axios.post(
      `${BASE_URL}/get-select-values?instanceIdentifier=${instanceId}`,
      { data }
    );
    return selectValues;
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const getSelectRadioData = async ({ instanceId, data }) => {
  try {
    const {
      data: { options },
    } = await axios.post(
      `${BASE_URL}/get-select-radio-values?instanceIdentifier=${instanceId}`,
      { data }
    );
    return options;
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const getRecordDetails = async ({ instanceId, recordId }) => {
  try {
    const {
      data: { recordDetails },
    } = await axios.get(
      `${BASE_URL}/get-record-details/${recordId}?instanceIdentifier=${instanceId}`
    );
    return recordDetails;
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const createCharge = async ({ paymentInfo, instanceId }) => {
  try {
    // get the charge token
    const {
      data: {
        createdCharge: {
          result: {
            chargeResponse: { token },
          },
        },
      },
    } = await axios.post(
      `${BASE_URL}/create-charge?instanceIdentifier=${instanceId}`,
      { paymentInfo }
    );
    return token;
  } catch (err) {
    console.error("PinPayment Error ", err);
  }
};

export const getIP = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  return res.data.IPv4;
};

export const createTransaction = async ({ instanceId, formData }) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/create-transaction?instanceIdentifier=${instanceId}`,
      { formData }
    );
    return data;
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const getPinPaymentDetails = async (instanceId) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/get-pinpayments-details?instanceIdentifier=${instanceId}`
    );
    return data;
  } catch (err) {
    console.error("API error: ", err);
  }
};

export const checkForDuplicateTransactions = async ({
  memberId,
  courseId = "",
  sessionId = "",
  instanceIdentifier,
}) => {
  try {
    const params = {
      memberId,
      courseId,
      sessionId,
      instanceIdentifier,
    };
    const { data } = await axios.get(
      `${BASE_URL}/check-for-duplicate-transactions`,
      { params }
    );
    return data;
  } catch (error) {
    console.error("API error: ", error);
  }
};
