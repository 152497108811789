import { Label, TextField, PhoneField } from "@clevero/components";

const EmergencyContact = ({ formValue, changeHandler, theme }) => {
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>EMERGENCY CONTACT</h4>
        <p>
          In the event of an emergency or we are unable to reach you directly,
          please provide an alternative contact.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label
            label="Emergency Contact Name"
            name="EmergencyContactName"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <TextField
            name="emergency-contact-name"
            value={formValue["emergency-contact-name"]}
            onChange={changeHandler}
          />
        </div>
        <div className="acfe-form-field">
          <Label
            label="Emergency Contact Mobile No"
            name="EmergencyContactNumber"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <PhoneField
            name="emergency-contact-number"
            value={formValue["emergency-contact-number"]}
            onChange={changeHandler}
          />
        </div>
      </div>
    </section>
  );
};

export default EmergencyContact;
