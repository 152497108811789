import { useState, useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import mapOptions from "./MapOptions";

const Map = (props) => {
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  useEffect(() => {
    async function setData() {
      Geocode.setApiKey(process.env.REACT_APP_API_KEY);
      Geocode.setLanguage("en");

      const response = await Geocode.fromAddress(props.address);
      const { lat, lng } = response.results[0].geometry.location;

      setCoordinates({ lat, lng });
    }
    setData();
  }, [props.address]);

  return (
    <>
      {coordinates.lat && coordinates.lng && (
        <GoogleMap
          defaultZoom={16}
          defaultCenter={{ lat: coordinates.lat, lng: coordinates.lng }}
          options={mapOptions}
        >
          <Marker
            position={{
              lat: coordinates.lat,
              lng: coordinates.lng,
            }}
          />
        </GoogleMap>
      )}
    </>
  );
};

export default withScriptjs(withGoogleMap(Map));
