import { Label, RadioField, TextField } from "@clevero/components";

const EmergencyContact = ({ formValue, changeHandler, theme, selectData }) => {
  const Genders = selectData;
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>GENDER IDENTITY</h4>
        <p>
          A <em style={{ color: theme.primaryColor }}>preferred pronoun</em> is
          how you wish people to address you. For example you may wish to be
          referred to as a{" "}
          <em style={{ color: theme.secondaryColor }}>he/him</em>,{" "}
          <em style={{ color: theme.secondaryColor }}>she/her</em>, or
          <em style={{ color: theme.secondaryColor }}>they/them</em>.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label
            label="Gender"
            name="Gender"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <RadioField
            name="gender"
            value={formValue.gender && +JSON.parse(formValue.gender)[0]}
            options={
              Genders && Genders.length !== 0
                ? Genders.map((Gender) => ({
                    value: Gender.value,
                    label: Gender.label,
                  }))
                : null
            }
            onChange={changeHandler}
            orientation="horizontal"
          />
        </div>
        <div className="acfe-form-field">
          <Label
            label="Preferred Pronoun"
            name="PreferredPronoun"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <TextField
            name="preferred-pronoun"
            value={formValue["preferred-pronoun"]}
            onChange={changeHandler}
          />
        </div>
      </div>
    </section>
  );
};

export default EmergencyContact;
