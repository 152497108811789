import { useEffect, useState } from "react";
import { HorizontalInlineLoader } from "@clevero/components";

const DetailsImage = ({ Course }) => {
  const [imgProps, setImgProps] = useState({ loaded: false, src: null });

  useEffect(() => {
    const detailsImage = new Image();
    detailsImage.src =
      Course["website-image"] ||
      "https://s3.ap-southeast-2.amazonaws.com/image-uploads.clevero.co/NH_placeholder_image.png";

    detailsImage.onload = () => {
      setImgProps({ loaded: true, src: detailsImage.src });
    };
  }, [Course]);

  return (
    <div
      className="details-side-container"
      style={{
        backgroundImage: `url(${imgProps.src})`,
      }}
    >
      {!imgProps.loaded && (
        <HorizontalInlineLoader className="horizontal-loader" />
      )}
    </div>
  );
};

export default DetailsImage;
