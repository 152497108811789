import { useLocation } from "react-router";
import { Label, TextDisplay, DropdownField } from "@clevero/components";

const Cost = ({
  coursePricing,
  theme,
  selectedAmount,
  setSelectedAmount,
  formError,
  setFormError,
}) => {
  const location = useLocation();
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>Cost</h4>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label mandatory label="Payment type" name="course-pricing" />
          <DropdownField
            name="course-pricing"
            value={selectedAmount}
            onChange={(e) => {
              setSelectedAmount(e.value);
              setFormError((prev) => ({ ...prev, [e.name]: null }));
            }}
            options={coursePricing.map((price) => ({
              label: price["price-level"],
              value:
                location.state && location.state.isProgramSession
                  ? `$${price["session-amount"]}` //session of programs
                  : `$${price["amount"]}`,
              required: true,
            }))}
            orientation={"vertical"}
            placeholder={"Select option"}
            error={formError.selectedAmount}
          />
        </div>
        <div className="acfe-form-field">
          <Label label="Cost" name="Cost" />
          <TextDisplay
            value={selectedAmount}
            noValueText={"Please select a payment type"}
          />
        </div>
      </div>
    </section>
  );
};

export default Cost;
