import React, { useContext, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import qs from "query-string";
import { ErrorBoundary } from "react-error-boundary";
import { AnimatePresence } from "framer-motion";
import { isLight, CleveroProvider } from "@clevero/components";

import { Theme } from "./context/ThemeProvider";
import ScrollToTop from "./Utils/ScrollToTop";

import Home from "./components/Home/Home";
import CoursePage from "./components/CoursePage/CoursePage";
import SessionPage from "./components/SessionPage/SessionPage";
import AcfeFormContainer from "./components/AcfeForms/AcfeFormContainer";
import PaymentPage from "./components/PaymentPage/PaymentPage";
import Success from "./components/SuccessError/Success";
import Error from "./components/SuccessError/Error";

const App = () => {
  const { theme, setTheme } = useContext(Theme);

  const location = useLocation();
  const { search } = location;
  const { p, s, bg } = qs.parse(search);

  useEffect(
    () => {
      // set theme
      if (p) setTheme((theme) => ({ ...theme, primaryColor: p }));
      if (s) setTheme((theme) => ({ ...theme, secondaryColor: s }));
      if (bg) setTheme((theme) => ({ ...theme, backgroundColor: bg }));

      setTheme((theme) =>
        isLight(theme.primaryColor)
          ? { ...theme, primaryTextColor: "#242424" }
          : { ...theme, primaryTextColor: "#ffffff" }
      );
      setTheme((theme) =>
        isLight(theme.secondaryColor)
          ? { ...theme, secondaryTextColor: "#242424" }
          : { ...theme, secondaryTextColor: "#ffffff" }
      );
    }, // eslint-disable-next-line
    [p, s, bg]
  );

  const errorHandler = (error, errorInfo) => {
    console.log("ERROR CAUGHT", error, errorInfo);
  };

  return (
    <CleveroProvider
      color={theme.primaryColor}
      buttons={{ color: theme.primaryColor }}
    >
      <div className="App" style={{ backgroundColor: theme.backgroundColor }}>
        <AnimatePresence exitBeforeEnter>
          <ErrorBoundary
            key={location.pathname}
            FallbackComponent={Error}
            onError={errorHandler}
          >
            <ScrollToTop />
            <Switch location={location} key={location.key}>
              <Route path="/home/:instance_identifier" component={Home} exact />
              <Route
                path="/:instance_identifier/course/:courseId"
                component={CoursePage}
                exact
              />
              <Route
                path="/:instance_identifier/one-off-session/:sessionId"
                component={SessionPage}
                exact
              />
              <Route
                path="/:instance_identifier/acfe-form"
                component={AcfeFormContainer}
                exact
              />
              <Route path="/payment" component={PaymentPage} exact />
              <Route path="/success" component={Success} exact />
              <Route path="/error" component={Error} exact />
              <Route path="/">
                <Redirect to="/home/:instance_identifier" />
              </Route>
            </Switch>
          </ErrorBoundary>
        </AnimatePresence>
      </div>
    </CleveroProvider>
  );
};

export default App;
