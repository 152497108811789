import { useState, useContext } from "react";
import { IoIosArrowDropdown } from "react-icons/io";

import FilterCategory from "./FilterCategory";

import { Theme } from "../../context/ThemeProvider";
import "../../styles/MobileFilter.css";

const MobileFilter = ({ radioField, setRadioField }) => {
  const { theme } = useContext(Theme);

  const [showFilter, setShowFilter] = useState(false);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="mobile-filter-container">
      <button
        className="filter-button"
        onClick={toggleFilter}
        style={{ color: theme.primaryColor }}
      >
        Filters
        <IoIosArrowDropdown
          className={
            showFilter ? "filter-button-icon icon-active" : "filter-button-icon"
          }
          style={{ color: theme.primaryColor }}
        />
      </button>
      {showFilter ? (
        <FilterCategory radioField={radioField} setRadioField={setRadioField} />
      ) : null}
    </div>
  );
};

export default MobileFilter;
