import React, { useState, createContext } from "react";

export const Theme = createContext();

const ThemeProvider = (props) => {
  const [theme, setTheme] = useState({
    primaryColor: "#EF634C",
    secondaryColor: "#4B78ED",
    primaryTextColor: "#242424",
    secondaryTextColor: "#242424",
    backgroundColor: "#ffffff",
  });
  return (
    <Theme.Provider value={{ theme, setTheme }}>
      {props.children}
    </Theme.Provider>
  );
};

export default ThemeProvider;
