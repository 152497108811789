import { useContext } from "react";
import { motion } from "framer-motion";
import { Theme } from "../context/ThemeProvider";
import "../styles/Spinner.css";

const Spinner = ({ hasFilter = null }) => {
  const { theme } = useContext(Theme);
  return (
    <motion.div
      className="loader-ring"
      style={{
        color: theme.primaryColor,
        y: hasFilter && hasFilter.filter === "false" ? "0%" : "-50%",
        x: "-50%",
      }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
    >
      Loading
    </motion.div>
  );
};

export default Spinner;
