import { Label, TextField, RadioField, NumberField } from "@clevero/components";

const ContactDetails = ({
  formValue,
  changeHandler,
  requiredFormValue,
  requiredChangeHandler,
  formError,
  theme,
  selectData,
}) => {
  const PreferredContactMethods = selectData;

  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>CONTACT DETAILS</h4>
        <p>
          At least one <em style={{ color: theme.primaryColor }}>phone no</em>,{" "}
          <em style={{ color: theme.primaryColor }}>mobile no</em>, or{" "}
          <em style={{ color: theme.primaryColor }}>email</em> methods of
          contact must be provided so that we can get in touch with you if need.
        </p>
        <p>
          To receive notifications an{" "}
          <em style={{ color: theme.primaryColor }}>email</em> address must be
          provided.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label mandatory label="Email" name="Email" />
          <TextField
            name="email-address"
            value={requiredFormValue["email-address"]}
            onChange={requiredChangeHandler}
            error={formError["email-address"]}
          />
        </div>
        <div className="acfe-form-field">
          <Label mandatory label="Mobile No" name="MobileNumber" />
          <NumberField
            name="telephone-number-mobile"
            value={requiredFormValue["telephone-number-mobile"]}
            onChange={requiredChangeHandler}
            hideControls
            error={formError["telephone-number-mobile"]}
          />
        </div>
        <div className="acfe-form-field">
          <Label mandatory label="Phone No" name="PhoneNumber" />
          <NumberField
            name="telephone-number-home"
            value={requiredFormValue["telephone-number-home"]}
            onChange={requiredChangeHandler}
            hideControls
            error={formError["telephone-number-home"]}
          />
        </div>
        <div className="acfe-form-field">
          <Label
            mandatory
            label="Preferred Contact Method"
            name="PreferredContactMethod"
          />
          <RadioField
            name="preferred-contact-method"
            value={
              (requiredFormValue["preferred-contact-method"] &&
                +JSON.parse(
                  requiredFormValue["preferred-contact-method"]
                )[0]) ||
              null
            }
            onChange={requiredChangeHandler}
            isLoading={
              !(PreferredContactMethods && PreferredContactMethods.length !== 0)
            }
            options={
              PreferredContactMethods && PreferredContactMethods.length !== 0
                ? PreferredContactMethods.map((PreferredContactMethod) => ({
                    value: PreferredContactMethod.value,
                    label: PreferredContactMethod.label,
                  }))
                : null
            }
            error={formError["preferred-contact-method"]}
          />
        </div>
        <div className="acfe-form-field">
          <Label label="Building/Property Name" name="BuildingName" />
          <TextField
            name="address-building-property-name"
            value={formValue["address-building-property-name"]}
            onChange={changeHandler}
          />
        </div>
        <div className="acfe-form-field">
          <Label label="Unit or Apartment no" name="ApartmentNo" />
          <TextField
            name="address-flat-unit-details"
            value={formValue["address-flat-unit-details"]}
            onChange={changeHandler}
          />
        </div>
        <div className="acfe-form-field">
          <Label label="Street Name" name="StreetName" />
          <TextField
            name="address-street-name"
            value={formValue["address-street-name"]}
            onChange={changeHandler}
          />
        </div>
        <div className="acfe-form-field">
          <Label label="Street Number" name="StreetNo" />
          <TextField
            name="address-street-number"
            value={formValue["address-street-number"]}
            onChange={changeHandler}
          />
        </div>
        <div className="acfe-form-field">
          <Label label="Town/Locality Name" name="TownName" />
          <TextField
            name="address--suburb-locality-or-town"
            value={formValue["address--suburb-locality-or-town"]}
            onChange={changeHandler}
          />
        </div>
      </div>
    </section>
  );
};

export default ContactDetails;
