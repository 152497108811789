import { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useErrorHandler } from "react-error-boundary";
import { AnimatePresence } from "framer-motion";

import { IoChevronBackCircle } from "react-icons/io5";
import { HorizontalInlineLoader } from "@clevero/components";

import CourseDetails from "./CourseDetails";
import CoursePageWrapper from "./CoursePageWrapper";
import DetailsImage from "./DetailsImage";
import RegisterForm from "./RegisterForm";
import SessionRegisterForm from "../SessionPage/RegisterForm";
import SessionsContainer from "./SessionsContainer";

import { Theme } from "../../context/ThemeProvider";

import { HTMLresolver } from "../../Utils/HTMLresolver";
import Modal from "../../Utils/Modal";
import Spinner from "../../Utils/Spinner";
import MapLocation from "../../Utils/MapLocation";

import {
  getEntry,
  getSessionsOfCourse,
  getSubRecordEntry,
  getPinPaymentDetails,
} from "../../api/axios";

const loadingElementStyle = {
  height: "450px",
  width: "100%",
  borderRadius: "1rem",
  backgroundColor: "rgb(229, 227, 223)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const CoursePage = () => {
  const { theme } = useContext(Theme);

  const [pinpaymentDetails, setPinPaymentDetails] = useState({});

  const [register, setRegister] = useState(false);
  const [entry, setEntry] = useState(null);
  const [sessions, setSessions] = useState(null);
  const [entryPricing, setEntryPricing] = useState(null);

  // for a single session of a program
  const [registerSession, setRegisterSession] = useState(false);
  const [session, setSession] = useState(null);
  const [isMember, setIsMember] = useState(false);

  const params = useParams();
  const history = useHistory();

  const handleError = useErrorHandler();

  useEffect(() => {
    const getCourseEntryAndUpdateURL = async () => {
      try {
        const courseEntryDetails = await getEntry({
          entryId: params.courseId,
          type: "course",
          instanceId: params.instance_identifier,
        });
        console.log(
          "%c Course Entry Details => ",
          "background: #ADF0D1; color: #00203F; font-weight:bold; font-family:Fira Code;",
          courseEntryDetails
        );
        setEntry(courseEntryDetails);
      } catch (e) {
        handleError(e);
      }
    };
    getCourseEntryAndUpdateURL();

    // get course pricing sub record
    const getCoursePricing = async () => {
      try {
        const courseEntryPricing = await getSubRecordEntry({
          entryId: params.courseId,
          instanceId: params.instance_identifier,
        });
        console.log(
          "%c Course Pricing => ",
          "background: #ADF0D1; color: #00203F; font-weight:bold; font-family:Fira Code;",
          courseEntryPricing
        );
        setEntryPricing(courseEntryPricing);
      } catch (e) {
        handleError(e);
      }
    };
    getCoursePricing();

    // get all sessions of a course
    const getSessions = async () => {
      try {
        const sessions = await getSessionsOfCourse({
          courseId: params.courseId,
          instanceId: params.instance_identifier,
        });
        console.log(
          "%c Sessions => ",
          "background: #ADF0D1; color: #00203F; font-weight:bold; font-family:Fira Code;",
          sessions
        );

        setSessions(sessions);
      } catch (e) {
        handleError(e);
      }
    };
    getSessions();

    (async function () {
      try {
        const pinpaymentDetails = await getPinPaymentDetails(
          params.instance_identifier
        );
        setPinPaymentDetails(pinpaymentDetails);
      } catch (err) {
        handleError(err);
      }
    })();
  }, [params.instance_identifier, params.courseId, handleError]);

  const handleSessionRegistration = ({ session }) => {
    setRegisterSession(true);
    setSession(session);
  };

  const goBack = () => {
    history.goBack(-1);
  };

  return !entry || !entryPricing ? (
    <Spinner />
  ) : (
    <>
      <CoursePageWrapper>
        <div className="back-btn" onClick={goBack}>
          <IoChevronBackCircle size="1.25rem" />
          Back
        </div>

        <div className="course-wrapper">
          <CourseDetails
            Course={entry}
            EntryPricing={entryPricing}
            Register={register}
            SetRegister={setRegister}
            InstanceId={params.instance_identifier}
          />

          <DetailsImage Course={entry} />
        </div>

        {sessions ? (
          <>
            <SessionsContainer
              Course={entry}
              sessions={
                (entry.sessionRegistration === "true" &&
                  sessions.filter(
                    (session) => new Date(session["start-time"]) > new Date()
                  )) ||
                sessions
              }
              EntryPricing={entryPricing}
              sessionRegistration={entry.sessionRegistration}
              handleSessionRegistration={handleSessionRegistration}
            />
          </>
        ) : null}

        {entry.location && (
          <>
            <hr
              className="faded-hr"
              style={{
                background: theme.primaryColor,
                backgroundImage: `linear-gradient(to right, #ccc, ${theme.primaryColor}, #ccc)`,
              }}
            />

            <div>
              <h2 className="sub-heading">Location</h2>
              <MapLocation
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={
                  <div style={loadingElementStyle}>
                    <HorizontalInlineLoader style={{ fontSize: "1.5rem" }} />
                  </div>
                }
                containerElement={
                  <div
                    style={{
                      height: "450px",
                      width: "100%",
                      backgroundColor: "rgb(229, 227, 223)",
                      borderRadius: "1rem",
                    }}
                  />
                }
                mapElement={
                  <div style={{ height: `100%`, borderRadius: "1rem" }} />
                }
                address={entry.location}
              />
            </div>
          </>
        )}

        {(entry["contact-name"] ||
          entry["contact-email"] ||
          entry["contact-phone"]) && (
          <>
            <hr
              className="faded-hr"
              style={{
                background: theme.primaryColor,
                backgroundImage: `linear-gradient(to right, #ccc, ${theme.primaryColor}, #ccc)`,
              }}
            />

            <h2 className="sub-heading">Contact Details</h2>

            <div className="contact-details">
              {entry["contact-name"] && (
                <div>
                  <b>{entry["contact-name"]}</b>
                </div>
              )}

              {entry["contact-email"] && (
                <div>
                  <span>Email:&nbsp;</span>
                  {entry["contact-email"]}
                </div>
              )}

              {entry["contact-phone"] && (
                <div>
                  <span>Phone:&nbsp;</span>
                  {entry["contact-phone"]}
                </div>
              )}
            </div>
          </>
        )}

        {entry["terms-and-conditions"] && (
          <>
            <hr
              className="faded-hr"
              style={{
                background: theme.primaryColor,
                backgroundImage: `linear-gradient(to right, #ccc, ${theme.primaryColor}, #ccc)`,
              }}
            />

            <div className="page-block">
              <h2 className="sub-heading">Terms and Conditions</h2>

              <p
                className="block-content"
                dangerouslySetInnerHTML={HTMLresolver(
                  entry["terms-and-conditions"],
                  ""
                )}
              />
            </div>
          </>
        )}

        {entry["cancellation-and-refund-policy"] && (
          <>
            <hr
              className="faded-hr"
              style={{
                background: theme.primaryColor,
                backgroundImage: `linear-gradient(to right, #ccc, ${theme.primaryColor}, #ccc)`,
              }}
            />

            <div className="page-block">
              <h2 className="sub-heading">Cancellation and Refund Policy</h2>

              <p
                className="block-content"
                dangerouslySetInnerHTML={HTMLresolver(
                  entry["cancellation-and-refund-policy"],
                  ""
                )}
              />
            </div>
          </>
        )}

        <AnimatePresence initial={false} exitBeforeEnter>
          {register &&
            (entry.isAcfe !== "true" || entry["free-course"] === "true") &&
            ((!isMember && (
              <Modal handleClose={() => setRegister(false)} key={"not member"}>
                <RegisterForm
                  Course={entry}
                  EntryPricing={entryPricing}
                  IsFree={
                    entry["free-course"] === "true" ||
                    !entryPricing.coursePricing.length
                  }
                  setIsMember={setIsMember}
                  pinpaymentDetails={pinpaymentDetails}
                />
              </Modal>
            )) || (
              <Modal handleClose={() => setRegister(false)} key={"member"}>
                <RegisterForm
                  Course={entry}
                  EntryPricing={entryPricing}
                  IsFree={
                    entry["free-course"] === "true" ||
                    !entryPricing.coursePricing.length
                  }
                  isMember={isMember}
                  setIsMember={setIsMember}
                  pinpaymentDetails={pinpaymentDetails}
                />
              </Modal>
            ))}

          {registerSession &&
            (entry.isAcfe !== "true" || entry["free-course"] === "true") &&
            ((!isMember && (
              <Modal
                handleClose={() => setRegisterSession(false)}
                key={"not member"}
              >
                <SessionRegisterForm
                  Course={entry}
                  Session={session}
                  EntryPricing={entryPricing}
                  IsFree={
                    entry["free-course"] === "true" ||
                    !entryPricing.coursePricing.length
                  }
                  setIsMember={setIsMember}
                  pinpaymentDetails={pinpaymentDetails}
                />
              </Modal>
            )) || (
              <Modal handleClose={() => setRegisterSession(false)}>
                <SessionRegisterForm
                  Course={entry}
                  Session={session}
                  EntryPricing={entryPricing}
                  IsFree={
                    entry["free-course"] === "true" ||
                    !entryPricing.coursePricing.length
                  }
                  isMember={isMember}
                  setIsMember={setIsMember}
                  pinpaymentDetails={pinpaymentDetails}
                />
              </Modal>
            ))}
        </AnimatePresence>
      </CoursePageWrapper>
    </>
  );
};

export default CoursePage;
