import fromPairs from "lodash/fromPairs";

const doesFormHasError = (formValue, emailKey = null, setFormError) => {
  const errObj = fromPairs(
    Object.keys(formValue).map((key) =>
      formValue[key] === "[]" ? [key, true] : [key, !formValue[key]]
    )
  );
  if (errObj[emailKey]) {
    setFormError(errObj);
  } else {
    let re =
      /* eslint-disable-next-line */
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    errObj[emailKey] = re.test(String(formValue[emailKey]).toLowerCase())
      ? false
      : "Invalid email";
    setFormError(errObj);
  }
  return Object.values(errObj).some((value) => value);
};

export default doesFormHasError;
