import { useContext, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useErrorHandler } from "react-error-boundary";
import qs from "query-string";

import { motion } from "framer-motion";

import EntriesSection from "./EntriesSection";
import SideBar from "./SideBar";

import { Global } from "../../context/GlobalProvider";
import "../../styles/Home.css";

import { getAPI, getCategories } from "../../api/axios";
import { getCategoryIdFromURL } from "../../Utils/getCategoryId";

const Home = () => {
  const { instance_identifier } = useParams();
  const { setEntries, instanceId, setInstanceId, setCategories } =
    useContext(Global);

  const handleError = useErrorHandler();

  const homeVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
    exit: { y: "-100vh", opacity: 0 },
  };

  const location = useLocation();
  let { filter = true } = qs.parse(location.search);
  filter = !(filter === "false");

  useEffect(() => {
    // set instance identifier
    setInstanceId(instance_identifier);

    // for pre-filtering of course/session entries
    const categoryId = getCategoryIdFromURL(location);

    // get entries from API
    async function getDataFromAPI() {
      try {
        const resEntries = await getAPI({
          instanceId,
          categoryPreFilterId: categoryId,
        });
        console.log(
          "%c LOADED ENTRIES => ",
          "background: #ADF0D1; color: #00203F; font-weight:bold; font-family:Fira Code;",
          resEntries
        );
        setEntries(resEntries);
      } catch (e) {
        handleError(e);
      }
    }

    //get categories from API
    async function fetchCategories() {
      try {
        const resCategories = await getCategories({ instanceId });
        console.log(
          "%c LOADED CATEGORIES =>",
          "background: #ADF0D1; color: #00203F; font-weight:bold; font-family:Fira Code;",
          resCategories
        );
        setCategories(resCategories);
      } catch (e) {
        handleError(e);
      }
    }

    if (instanceId) {
      getDataFromAPI();
      fetchCategories();
    }
  }, [
    location,
    instance_identifier,
    setEntries,
    instanceId,
    setInstanceId,
    setCategories,
    handleError,
  ]);

  return (
    <motion.main
      className="home-container"
      style={{ gridTemplateColumns: !filter && "auto" }}
      variants={homeVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {filter && <SideBar />}
      <EntriesSection />
    </motion.main>
  );
};

export default Home;
