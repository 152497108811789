import {
  Label,
  CheckboxField,
  DropdownField,
  TextAreaField,
} from "@clevero/components";
import { useEffect } from "react";

const MedicalCondition = ({
  formValue,
  checkboxValue,
  setCheckboxValue,
  changeHandler,
  theme,
  selectData,
}) => {
  const MedicalConditionTypes = selectData;

  useEffect(() => {
    if (!checkboxValue.MedicalCondition) {
      changeHandler({ name: "MedicalConditionType", value: "" });
      changeHandler({ name: "OtherHealthInfo", value: "" });
    }
  }, [checkboxValue.MedicalCondition, changeHandler]);
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>
          MEDICAL CONDITION OR DISABILITY
        </h4>
        <p>
          If you have a{" "}
          <em style={{ color: theme.primaryColor }}>medical condition</em> or{" "}
          <em style={{ color: theme.primaryColor }}>disability</em> that we
          should be aware of, please indicate what they are.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <CheckboxField
            label="Has a medical condition, disability or allergy that may impact participation"
            name="MedicalCondition"
            value={checkboxValue.MedicalCondition}
            onChange={() => {
              setCheckboxValue({
                ...checkboxValue,
                MedicalCondition: !checkboxValue.MedicalCondition,
              });
            }}
          />
        </div>
        {checkboxValue.MedicalCondition && (
          <>
            <div className="acfe-form-field">
              <Label
                label="Medical condition or Disability"
                name="MedicalConditionType"
              />
              <DropdownField
                name="disability-type-identifier"
                value={
                  formValue["disability-type-identifier"] &&
                  +JSON.parse(formValue["disability-type-identifier"])[0]
                }
                onChange={changeHandler}
                options={
                  MedicalConditionTypes && MedicalConditionTypes.length !== 0
                    ? MedicalConditionTypes.map((MedicalConditionType) => ({
                        value: MedicalConditionType.value,
                        label: MedicalConditionType.label,
                      }))
                    : null
                }
              />
            </div>
            <div className="acfe-form-field">
              <Label label="Other Health Information" name="OtherHealthInfo" />
              <TextAreaField
                name="other-health-information"
                value={formValue["other-health-information"]}
                onChange={changeHandler}
                rows={5}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default MedicalCondition;
