import { useContext, useState, useCallback } from "react";
import { useHistory } from "react-router";

import fromPairs from "lodash/fromPairs";
import { motion } from "framer-motion";
import { SimpleTile, TileBody, Button } from "@clevero/components";
import { IoChevronBackCircle } from "react-icons/io5";

import { Theme } from "../../context/ThemeProvider";

import ContactDetails from "./ContactDetails";
import EducationalAchievement from "./EducationalAchievement";
import EmergencyContact from "./EmergencyContact";
import GenderIdentity from "./GenderIdentity";
import Identity from "./Identity";
import IndigenousStatus from "./IndigenousStatus";
import PrimaryLanguage from "./PrimaryLanguage";
import ResidencyStatus from "./ResidencyStatus";
import SchoolStatus from "./SchoolStatus";
import VSN from "./VSN";
import EmploymentStatus from "./EmploymentStatus";
import ConcessionEntitlement from "./ConcessionEntitlement";
import MedicalCondition from "./MedicalCondition";
import Cost from "./Cost";

// import validateForm from "../../Utils/validateForm";
import "../../styles/AcfeForm.css";

import { useErrorHandler } from "react-error-boundary";
import { registerAcfeEntry } from "../../api/axios";

const AcfeForm = ({
  selectData,
  acfeFields,
  requiredAcfeFields,
  entry,
  coursePricing,
  instance_identifier,
}) => {
  const { theme } = useContext(Theme);
  const history = useHistory();
  const handleError = useErrorHandler();
  const [formValue, setFormValue] = useState(
    fromPairs(Object.keys(acfeFields).map((key) => [key, ""]))
  );
  const [requiredFormValue, setRequiredFormValue] = useState(
    fromPairs(Object.keys(requiredAcfeFields).map((key) => [key, ""]))
  );
  const [formError, setFormError] = useState({});
  const [selectedAmount, setSelectedAmount] = useState(
    !Array.isArray(coursePricing) ? coursePricing : ""
  );

  const [checkboxValue, setCheckboxValue] = useState({
    ConcessionEntitlement: false,
    MedicalCondition: false,
  });

  const changeHandler = useCallback(
    ({ name, value }) => {
      const field = acfeFields[name];

      if (!field) return;
      if (
        field.fieldType.internalId === "select" ||
        field.fieldType.internalId === "radio" ||
        field.fieldType.internalId === "multiselect"
      ) {
        setFormValue((prevValue) => ({
          ...prevValue,
          [field.internalId]: value
            ? JSON.stringify([`${value}`])
            : JSON.stringify([]),
        }));
      } else {
        setFormValue((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
      }
    },
    [acfeFields]
  );

  const requiredChangeHandler = useCallback(
    ({ name, value }) => {
      const field = requiredAcfeFields[name];

      if (!field) return;
      if (
        field.fieldType.internalId === "select" ||
        field.fieldType.internalId === "radio" ||
        field.fieldType.internalId === "multiselect"
      ) {
        setRequiredFormValue((prevValue) => ({
          ...prevValue,
          [field.internalId]: value
            ? JSON.stringify([`${value}`])
            : JSON.stringify([]),
        }));
      } else {
        setRequiredFormValue((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
      }
      setFormError({});
    },
    [requiredAcfeFields]
  );

  const submitHandler = async () => {
    // const submitErr = await validateForm(
    //   { ...requiredFormValue, selectedAmount },
    //   "email-address",
    //   setFormError
    // );

    let submitErr;

    console.log(
      "%c Form Error => ",
      "background: #990012; color: #FCF7F4; font-weight:normal; font-family:Fira Code;",
      submitErr
    );

    if (submitErr) return;

    console.log(
      "%c Form Entry",
      "background: #ADF0D1; color: #00203F; font-weight:bold; font-family:Fira Code;",
      formValue
    );

    try {
      const res = await registerAcfeEntry({
        instanceId: instance_identifier,
        formData: formValue,
      });

      console.log(
        "%c User Registration Response => ",
        "background: #068282; color: #95DBE5; font-weight:bold; font-family:Fira Code;",
        res
      );

      if (res.entryCreated) {
        history.push({
          pathname: "/payment",
          state: {
            amount: selectedAmount,
            instance_identifier,
            entry,
            burrowedFormValue: requiredFormValue,
          },
        });
      } else {
        history.push({
          pathname: "/error",
        });
      }
    } catch (e) {
      handleError(e);
    }
  };

  const acfeFormVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
    exit: { y: "-100vh", opacity: 0 },
  };

  const goBack = () => {
    history.goBack(-1);
  };

  return (
    <motion.div
      variants={acfeFormVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="back-btn" onClick={goBack}>
        <IoChevronBackCircle size="1.25rem" />
        Back to Course
      </div>

      <SimpleTile className={"acfe-form-container"}>
        <TileBody className={"acfe-form-body"}>
          <Identity
            formValue={formValue}
            changeHandler={changeHandler}
            requiredFormValue={requiredFormValue}
            requiredChangeHandler={requiredChangeHandler}
            formError={formError}
            theme={theme}
            selectData={selectData["client-title"] || []}
          />
          <ContactDetails
            formValue={formValue}
            changeHandler={changeHandler}
            requiredFormValue={requiredFormValue}
            requiredChangeHandler={requiredChangeHandler}
            formError={formError}
            theme={theme}
            selectData={selectData["preferred-contact-method"] || []}
          />
          <hr />
          <EmergencyContact
            formValue={formValue}
            changeHandler={changeHandler}
            theme={theme}
          />
          <hr />
          <ConcessionEntitlement
            formValue={formValue}
            setCheckboxValue={setCheckboxValue}
            changeHandler={changeHandler}
            checkboxValue={checkboxValue}
            theme={theme}
            selectData={selectData["concession-type"] || []}
          />
          <MedicalCondition
            formValue={formValue}
            setCheckboxValue={setCheckboxValue}
            changeHandler={changeHandler}
            checkboxValue={checkboxValue}
            theme={theme}
            selectData={selectData["disability-type-identifier"] || []}
          />
          <hr />
          <GenderIdentity
            formValue={formValue}
            changeHandler={changeHandler}
            theme={theme}
            selectData={selectData["gender"] || []}
          />
          <IndigenousStatus
            formValue={formValue}
            changeHandler={changeHandler}
            requiredFormValue={requiredFormValue}
            requiredChangeHandler={requiredChangeHandler}
            formError={formError}
            theme={theme}
            selectData={selectData["indigenous-status-identifier"] || []}
          />
          <PrimaryLanguage
            formValue={formValue}
            changeHandler={changeHandler}
            requiredFormValue={requiredFormValue}
            requiredChangeHandler={requiredChangeHandler}
            formError={formError}
            theme={theme}
          />
          <ResidencyStatus
            formValue={formValue}
            changeHandler={changeHandler}
            theme={theme}
            selectData={selectData["current-residency-status"] || []}
          />
          <hr />
          <SchoolStatus
            formValue={formValue}
            changeHandler={changeHandler}
            theme={theme}
            selectData={
              selectData["highest-school-level-completed-identifier"] || []
            }
          />
          <VSN
            formValue={formValue}
            changeHandler={changeHandler}
            theme={theme}
          />
          <EducationalAchievement
            formValue={formValue}
            changeHandler={changeHandler}
            requiredFormValue={requiredFormValue}
            requiredChangeHandler={requiredChangeHandler}
            formError={formError}
            theme={theme}
            selectData1={
              selectData["australian-qualification"] ||
              selectData["australian-equivalent"] ||
              selectData["international-qualification"] ||
              []
            }
            selectData2={selectData["study-reason"] || []}
          />
          <hr />
          <EmploymentStatus
            formValue={formValue}
            changeHandler={changeHandler}
            theme={theme}
            selectData1={selectData["employment-status"] || []}
            selectData2={selectData["industry-of-employment"] || []}
          />
          <hr />

          {coursePricing &&
          Array.isArray(coursePricing) &&
          coursePricing.length > 0 ? (
            <Cost
              theme={theme}
              coursePricing={coursePricing}
              selectedAmount={selectedAmount}
              setSelectedAmount={setSelectedAmount}
              formError={formError}
              setFormError={setFormError}
            />
          ) : null}
          <div style={{ textAlign: "right" }}>
            <Button size={"md"} onClick={submitHandler}>
              Register
            </Button>
          </div>
        </TileBody>
      </SimpleTile>
    </motion.div>
  );
};

export default AcfeForm;
