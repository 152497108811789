import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import AcfeForm from "./AcfeForm";

import { getRecordDetails, getSelectRadioData } from "../../api/axios";

const fields = {
  "client-title": "",
  "preferred-name": "",
  "address-building-property-name": "",
  "address-flat-unit-details": "",
  "address-street-name": "",
  "address-street-number": "",
  "address--suburb-locality-or-town": "",
  "emergency-contact-name": "",
  "emergency-contact-number": "",
  "concession-type": "",
  "concession-expiry-date": "",
  "disability-type-identifier": "",
  "other-health-information": "",
  gender: "",
  "preferred-pronoun": "",
  "indigenous-status-identifier": "",
  "current-residency-status": "",
  "school-completion-date": "",
  "highest-school-level-completed-identifier": "",
  "victorian-student-number-vsn": "",
  "australian-qualification": "",
  "australian-equivalent": "",
  "international-qualification": "",
  "employment-status": "",
  "industry-of-employment": "",
};

const requiredFields = {
  "client-first-given-name": "",
  "client-family-name": "",
  "email-address": "",
  "telephone-number-mobile": "",
  "telephone-number-home": "",
  "preferred-contact-method": "",
  "date-of-birth": "",
  "birth-country": "",
  "primary-language": "",
  "study-reason": "",
};

const AcfeFormsContainer = () => {
  const params = useParams();
  const location = useLocation();
  const [selectData, setSelectData] = useState([]);

  useEffect(() => {
    (async function getRecordInfo() {
      const recordDetails = await getRecordDetails({
        instanceId: params.instance_identifier,
        recordId: "acfe-client",
      });

      Object.keys(fields).forEach((field) => {
        fields[field] = recordDetails.fields.find(
          ({ internalId }) => internalId === field
        );
      });

      Object.keys(requiredFields).forEach((field) => {
        requiredFields[field] = recordDetails.fields.find(
          ({ internalId }) => internalId === field
        );
      });

      let allFields = { ...fields, ...requiredFields };
      let selectFields = Object.keys(allFields).filter(
        (key) =>
          allFields[key] &&
          allFields[key].fieldType &&
          (allFields[key].fieldType.internalId === "select" ||
            allFields[key].fieldType.internalId === "radio")
      );

      let dummy = {};

      await Promise.all(
        selectFields.map(async (key) => {
          let data = await getSelectRadioData({
            instanceId: params.instance_identifier,
            data: {
              fieldId: "" + allFields[key].settings.options.field,
              recordId: "" + allFields[key].settings.options.record,
              fieldType: allFields[key].fieldType.internalId,
            },
          });
          dummy = { ...dummy, [key]: data };
        })
      );

      setSelectData(dummy);
    })();
  }, [params.instance_identifier]);

  return (
    <AcfeForm
      acfeFields={fields}
      requiredAcfeFields={requiredFields}
      selectData={selectData}
      entry={location.state.entry}
      coursePricing={location.state.coursePricing}
      instance_identifier={params.instance_identifier}
    />
  );
};

export default AcfeFormsContainer;
