import { Label, DropdownField } from "@clevero/components";

const EmployeeStatus = ({
  formValue,
  changeHandler,
  theme,
  selectData1,
  selectData2,
}) => {
  const EmploymentStatuses = selectData1;
  const IndustryOfEmployments = selectData2;
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>EMPLOYMENT STATUS</h4>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label
            label="Employment Status"
            name="EmploymentStatus"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <DropdownField
            name="employment-status"
            value={
              formValue["employment-status"] &&
              +JSON.parse(formValue["employment-status"])[0]
            }
            onChange={changeHandler}
            options={
              EmploymentStatuses && EmploymentStatuses.length !== 0
                ? EmploymentStatuses.map((EmploymentStatus) => ({
                    value: EmploymentStatus.value,
                    label: EmploymentStatus.label,
                  }))
                : null
            }
            placeholder={"Select option"}
          />
        </div>
        <div className="acfe-form-field">
          <Label
            label="Industry of Employment"
            name="IndustryOfEmployment"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <DropdownField
            name="industry-of-employment"
            value={
              formValue["industry-of-employment"] &&
              +JSON.parse(formValue["industry-of-employment"])[0]
            }
            onChange={changeHandler}
            options={
              IndustryOfEmployments && IndustryOfEmployments !== 0
                ? IndustryOfEmployments.map((IndustryOfEmployment) => ({
                    value: IndustryOfEmployment.value,
                    label: IndustryOfEmployment.label,
                  }))
                : null
            }
            placeholder={"Select option"}
          />
        </div>
      </div>
    </section>
  );
};

export default EmployeeStatus;
