import { useMemo } from "react";
import {
  Label,
  RadioField,
  DateField,
  DropdownField,
} from "@clevero/components";
import countryList from "react-select-country-list";

const IndigenousStatus = ({
  formValue,
  changeHandler,
  requiredFormValue,
  requiredChangeHandler,
  formError,
  theme,
  selectData,
}) => {
  const countries = useMemo(() => countryList().getLabels(), []);
  const IndigenousStatuses = selectData;
  return (
    <section className="afce-form-body-section">
      <div className="acfe-form-field-details">
        <h4 style={{ color: theme.primaryColor }}>INDIGENOUS STATUS</h4>
        <p>
          AIs required to provide information on the VET participation of
          Aboriginal / Torres Strait Islanders.
        </p>
      </div>
      <div className="acfe-form-field-wrapper">
        <div className="acfe-form-field">
          <Label
            label="Are you of Aboriginal or Torres Strait origin?"
            name="IndigenousStatus"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <RadioField
            name="indigenous-status-identifier"
            value={
              formValue["indigenous-status-identifier"] &&
              +JSON.parse(formValue["indigenous-status-identifier"])[0]
            }
            onChange={changeHandler}
            isLoading={!(IndigenousStatuses && IndigenousStatuses !== 0)}
            options={
              IndigenousStatuses && IndigenousStatuses !== 0
                ? IndigenousStatuses.map((IndigenousStatus) => ({
                    value: IndigenousStatus.value,
                    label: IndigenousStatus.label,
                  }))
                : null
            }
          />
        </div>
        <div className="acfe-form-field">
          <Label
            mandatory
            label="Date of Birth"
            name="Dob"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <DateField
            name="date-of-birth"
            value={requiredFormValue["date-of-birth"]}
            onChange={requiredChangeHandler}
            error={formError["date-of-birth"]}
          />
        </div>
        <div className="acfe-form-field">
          <Label
            mandatory
            label="In which country were you born?"
            name="BirthCountry"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <DropdownField
            name="birth-country"
            value={requiredFormValue["birth-country"]}
            onChange={requiredChangeHandler}
            options={countries.map((country) => ({
              label: country,
              value: country,
            }))}
            placeholder={"Select option"}
            error={formError["birth-country"]}
          />
        </div>
      </div>
    </section>
  );
};

export default IndigenousStatus;
