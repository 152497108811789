import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ThemeProvider from "./context/ThemeProvider";
import GlobalProvider from "./context/GlobalProvider";
import "./styles/GlobalStyles.css";

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <ThemeProvider>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById("nh-root")
);
