import { useContext } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";

import { motion } from "framer-motion";
import { Button } from "@clevero/components";

import CourseCard from "./CourseCard";
import SessionCard from "./SessionCard";
import Spinner from "../../Utils/Spinner";

import { Global } from "../../context/GlobalProvider";

const EntriesSection = () => {
  const { entries } = useContext(Global);
  const courseEntries = entries && entries.courseEntries;
  const sessionEntries = entries && entries.sessionEntries;

  const allEntries = entries &&
    entries.courseEntries &&
    entries.sessionEntries && [...courseEntries, ...sessionEntries];

  const containerVariants = {
    initial: { x: 500 },
    visible: {
      x: 0,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const cardVariants = {
    initial: { scale: 0.5, opacity: 0 },
    visible: { scale: 1, opacity: 1 },
  };

  const location = useLocation();
  const { search } = location;
  const filter = search && qs.parse(search);

  return (
    <section className="courses-section">
      {!entries ? (
        <Spinner hasFilter={filter} />
      ) : !allEntries.length ? (
        <Button size="lg" variant="outline" className={"info-btn"}>
          No Activities Available
        </Button>
      ) : (
        <>
          <motion.div
            className="courses-container"
            variants={containerVariants}
            initial="initial"
            animate="visible"
          >
            {allEntries
              .sort((EntryA, EntryB) => {
                return (
                  new Date(
                    (EntryA["start-date"] &&
                      `${EntryA["start-date"]} 23:00:00`) ||
                      EntryA["start-time"]
                  ) -
                  new Date(
                    (EntryB["start-date"] &&
                      `${EntryB["start-date"]} 23:00:00`) ||
                      EntryB["start-time"]
                  )
                );
              })
              .map((entry, key) => {
                if (entry.type === "Course") {
                  return (
                    <CourseCard
                      Course={entry}
                      key={key}
                      id={key}
                      cardVariants={cardVariants}
                    />
                  );
                } else if (entry.type === "Session") {
                  return (
                    <SessionCard
                      Session={entry}
                      key={key}
                      id={key}
                      cardVariants={cardVariants}
                    />
                  );
                } else return null;
              })}
          </motion.div>
        </>
      )}
    </section>
  );
};

export default EntriesSection;
