const PinPayments = () => {
  return (
    <div className="pin-payment-logo">
      Powered by&nbsp;
      <br />
      <img
        src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/v1397181473/d8d3f1b1eb17bcb08160de1d1c07c955.png"
        height="30"
        alt="Pin Payments"
      />
    </div>
  );
};

export default PinPayments;
