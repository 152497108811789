import { Button } from "@clevero/components";

import format from "date-fns/format";
import "../../styles/SessionContainer.css";

const Sessions = ({
  sessions,
  sessionRegistration,
  handleSessionRegistration,
}) => {
  return (
    <section className="details-sessions-container">
      <h1 className="sub-heading">Sessions</h1>

      <div className="details-sessions">
        <ul className="sessions-table">
          {(sessions &&
            sessions.length &&
            sessions
              .sort((SessionA, SessionB) => {
                return (
                  new Date(SessionA["start-time"]) -
                  new Date(SessionB["start-time"])
                );
              })
              .map((session) => (
                <li className="table-row" key={session.id}>
                  <div className="col col-1" data-label="Event date">
                    {session["start-time"]
                      ? format(
                          new Date(session["start-time"]),
                          "eeee do MMM yyyy - p"
                        )
                      : "N/A"}
                  </div>

                  {session["remaining-capacity"] && (
                    <div className="col col-2" data-label="Remaining seats">
                      {+session["remaining-capacity"] > 0
                        ? `${session["remaining-capacity"]} seats available`
                        : "Sold Out"}
                    </div>
                  )}

                  {sessionRegistration === "true" && (
                    <div className="col col-3" data-label="Register">
                      <Button
                        onClick={() => handleSessionRegistration({ session })}
                        disabled={
                          session["remaining-capacity"] !== "" &&
                          +session["remaining-capacity"] <= 0
                        }
                      >
                        Register
                      </Button>
                    </div>
                  )}
                </li>
              ))) || (
            <li className="table-row">
              <div className="col">No session available</div>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
};

export default Sessions;
