import { useCallback, useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import debounce from "lodash.debounce";

import { motion } from "framer-motion";
import { SimpleTile, Label, MultichoiceField } from "@clevero/components";

import { Global } from "../../context/GlobalProvider";
import { Theme } from "../../context/ThemeProvider";

import { getAPI } from "../../api/axios";
import { getCategoryIdFromURL } from "../../Utils/getCategoryId";

const FilterCategory = ({ radioField, setRadioField, y }) => {
  const { setEntries, instanceId, categories } = useContext(Global);
  const { theme } = useContext(Theme);

  const handleError = useErrorHandler();

  //radioCall: a flag to trigger api call only when handleSelectCategory is invoked.
  //prevents api hit for entries on component mount (which is done in home.jsx already)
  const [radioCall, setRadioCall] = useState(false);

  const dropVariants = {
    hidden: {
      y: -40,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const handleSelectCategory = (categoryId) => {
    setEntries(""); //to destroy the loaded cards on each filter request
    setRadioField(categoryId);
    setRadioCall(true);
  };

  // for pre-filtering of course/session entries
  const location = useLocation();
  const categoryId = getCategoryIdFromURL(location);

  useEffect(() => {
    if (instanceId && radioCall) callSelectCategory(radioField);
    // eslint-disable-next-line
  }, [radioField, radioCall, instanceId]);

  // eslint-disable-next-line
  const callSelectCategory = useCallback(
    debounce(
      async (radioValue) => {
        // filter based on categoryId
        console.log("filter API call for \n", " category: ", radioValue);

        try {
          const filteredRes = await getAPI({
            categoryId: radioValue.length ? radioValue : null,
            instanceId,
            categoryPreFilterId: !radioValue.length ? categoryId : null,
          });
          console.log(
            "%c FILTERED COURSES => ",
            "background: #ADF0D1; color: #00203F; font-weight:bold; font-family:Fira Code;",
            filteredRes
          );
          setEntries(filteredRes);
          setRadioCall(false);
        } catch (e) {
          handleError(e);
        }
      },
      [800] //debounce timer
    ),
    [instanceId]
  );

  return categories && categories.length ? (
    <motion.div variants={dropVariants} initial="hidden" animate="visible">
      <SimpleTile className="category-top-bar">
        <Label
          label="Categories"
          name="category-field"
          style={{
            marginBottom: "0.25rem",
            marginLeft: "1rem",
          }}
        />
        <MultichoiceField
          name={"category-field"}
          value={radioField}
          options={categories.map((category) => ({
            value: category.recordValueId,
            label: (
              <>
                {category.name}&nbsp;
                <span
                  style={{
                    color: theme.secondaryColor,
                    fontWeight: 600,
                    letterSpacing: 1.8,
                    opacity: 0.65,
                  }}
                >
                  ({category.length})
                </span>
              </>
            ),
          }))}
          onChange={(e) => {
            handleSelectCategory(e.value);
          }}
          orientation="vertical"
        />
      </SimpleTile>
    </motion.div>
  ) : null;
};

export default FilterCategory;
