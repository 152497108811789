import { useContext, useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useErrorHandler } from "react-error-boundary";
import { AnimatePresence } from "framer-motion";

import { IoChevronBackCircle } from "react-icons/io5";
import { HorizontalInlineLoader } from "@clevero/components";

import SessionDetails from "./SessionDetails";
import SessionPageWrapper from "./SessionPageWrapper";
import DetailsImage from "./DetailsImage";
import RegisterForm from "./RegisterForm";

import { Theme } from "../../context/ThemeProvider";

import { HTMLresolver } from "../../Utils/HTMLresolver";
import Modal from "../../Utils/Modal";
import Spinner from "../../Utils/Spinner";
import MapLocation from "../../Utils/MapLocation";

import { getEntry, getPinPaymentDetails } from "../../api/axios";

const loadingElementStyle = {
  height: "450px",
  width: "100%",
  borderRadius: "1rem",
  backgroundColor: "rgb(229, 227, 223)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const SessionPage = () => {
  const { theme } = useContext(Theme);

  const [pinpaymentDetails, setPinPaymentDetails] = useState({});

  const [register, setRegister] = useState(false);
  const [entry, setEntry] = useState(null);
  const [entryPricing, setEntryPricing] = useState(null);
  const [isMember, setIsMember] = useState(false);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const handleError = useErrorHandler();

  useEffect(() => {
    //if child session of program
    if (location.state && location.state.session) {
      setEntry(location.state.session);
      setEntryPricing(location.state.EntryPricing);
      console.log(
        "%c One of Session",
        "background: #ADF0D1; color: #00203F; font-weight:bold; font-family:Fira Code;"
      );
      return;
    }

    //if not child session of program
    const getSessionEntry = async () => {
      const sessionEntryDetails = await getEntry({
        entryId: params.sessionId,
        type: "session",
        instanceId: params.instance_identifier,
      });
      console.log(
        "%c Session Entry Details",
        "background: #ADF0D1; color: #00203F; font-weight:bold; font-family:Fira Code;",
        sessionEntryDetails
      );
      setEntry(sessionEntryDetails);
      setEntryPricing({ coursePricing: [] }); //for individual sessions (should not be null)
    };
    getSessionEntry();

    (async function () {
      try {
        const pinpaymentDetails = await getPinPaymentDetails(
          params.instance_identifier
        );
        setPinPaymentDetails(pinpaymentDetails);
      } catch (e) {
        handleError(e);
      }
    })();
  }, [
    params.instance_identifier,
    params.sessionId,
    handleError,
    location.state,
  ]);

  const goBack = () => {
    history.goBack(-1);
  };

  return !entry || !entryPricing ? (
    <Spinner />
  ) : (
    <SessionPageWrapper>
      <div className="back-btn" onClick={goBack}>
        <IoChevronBackCircle size="1.25rem" />
        Back
      </div>

      <div className="course-wrapper">
        <SessionDetails
          Session={entry}
          EntryPricing={entryPricing}
          Register={register}
          SetRegister={setRegister}
          InstanceId={params.instance_identifier}
        />

        <DetailsImage Session={entry} />
      </div>

      {entry["location-address"] && (
        <>
          <hr
            className="faded-hr"
            style={{
              background: theme.primaryColor,
              backgroundImage: `linear-gradient(to right, #ccc, ${theme.primaryColor}, #ccc)`,
            }}
          />

          <div>
            <h2 className="sub-heading">Location</h2>

            <MapLocation
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={
                <div style={loadingElementStyle}>
                  <HorizontalInlineLoader style={{ fontSize: "1.5rem" }} />
                </div>
              }
              containerElement={
                <div
                  style={{
                    height: "450px",
                    width: "100%",
                    backgroundColor: "rgb(229, 227, 223)",
                    borderRadius: "1rem",
                  }}
                />
              }
              mapElement={
                <div style={{ height: `100%`, borderRadius: "1rem" }} />
              }
              address={entry["location-address"]}
            />
          </div>
        </>
      )}

      {(entry["contact-name"] ||
        entry["contact-email"] ||
        entry["contact-phone"]) && (
        <>
          <hr
            className="faded-hr"
            style={{
              background: theme.primaryColor,
              backgroundImage: `linear-gradient(to right, #ccc, ${theme.primaryColor}, #ccc)`,
            }}
          />

          <h2 className="sub-heading">Contact Details</h2>

          <div className="contact-details">
            {entry["contact-name"] && (
              <div>
                <b>{entry["contact-name"]}</b>
              </div>
            )}

            {entry["contact-email"] && (
              <div>
                <span>Email:&nbsp;</span>
                {entry["contact-email"]}
              </div>
            )}

            {entry["contact-phone"] && (
              <div>
                <span>Phone:&nbsp;</span>
                {entry["contact-phone"]}
              </div>
            )}
          </div>
        </>
      )}

      {entry["terms-and-conditions"] && (
        <>
          <hr
            className="faded-hr"
            style={{
              background: theme.primaryColor,
              backgroundImage: `linear-gradient(to right, #ccc, ${theme.primaryColor}, #ccc)`,
            }}
          />

          <div className="page-block">
            <h2 className="sub-heading">Terms and Conditions</h2>

            <p
              className="block-content"
              dangerouslySetInnerHTML={HTMLresolver(
                entry["terms-and-conditions"],
                ""
              )}
            />
          </div>
        </>
      )}

      {entry["cancellation-and-refund-policy"] && (
        <>
          <hr
            className="faded-hr"
            style={{
              background: theme.primaryColor,
              backgroundImage: `linear-gradient(to right, #ccc, ${theme.primaryColor}, #ccc)`,
            }}
          />

          <div className="page-block">
            <h2 className="sub-heading">Cancellation and Refund Policy</h2>

            <p
              className="block-content"
              dangerouslySetInnerHTML={HTMLresolver(
                entry["cancellation-and-refund-policy"],
                ""
              )}
            />
          </div>
        </>
      )}

      <AnimatePresence initial={false} exitBeforeEnter>
        {register &&
          ((!isMember && (
            <Modal handleClose={() => setRegister(false)} key={"not member"}>
              <RegisterForm
                Session={entry}
                IsFree={
                  entry["free-session"] === "true" || !entry["activity-fee"]
                }
                EntryPricing={entryPricing}
                setIsMember={setIsMember}
                pinpaymentDetails={pinpaymentDetails}
              />
            </Modal>
          )) || (
            <Modal handleClose={() => setRegister(false)} key={"member"}>
              <RegisterForm
                Session={entry}
                IsFree={
                  entry["free-session"] === "true" || !entry["activity-fee"]
                }
                EntryPricing={entryPricing}
                isMember={isMember}
                setIsMember={setIsMember}
                pinpaymentDetails={pinpaymentDetails}
              />
            </Modal>
          ))}
      </AnimatePresence>
    </SessionPageWrapper>
  );
};

export default SessionPage;
