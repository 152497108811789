import { useCallback, useContext, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import {
  Tile,
  TileBody,
  TileHeader,
  Label,
  TextField,
  PhoneField,
  Button,
  ErrorText,
  TextDisplay,
  DropdownField,
  Alert,
  AlertBody,
} from "@clevero/components";
import { BiLinkExternal } from "react-icons/bi";

import doesFormHasError from "../../Utils/doesFormHasError";
import "../../styles/RegisterForm.css";

import { useErrorHandler } from "react-error-boundary";
import { checkForDuplicateTransactions, registerMember } from "../../api/axios";
import { createTransaction } from "../../api/axios";

import { Theme } from "../../context/ThemeProvider";

const RegisterForm = ({
  Session,
  Course,
  EntryPricing: { coursePricing },
  IsFree,
  isMember,
  setIsMember,
  pinpaymentDetails,
}) => {
  const { theme } = useContext(Theme);

  const location = useLocation();
  const { instance_identifier } = useParams();
  const handleError = useErrorHandler();
  const [formError, setFormError] = useState({});

  const [formValue, setFormValue] = useState({
    "start-date": "",
    address: "",
    ...(!isMember && { phone: "" }),
  });

  const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formChangeHandler = useCallback(({ name, value }) => {
    setFormValue((prevValue) => ({ ...prevValue, [name]: value }));
  }, []);

  const [requiredFormValue, setRequiredFormValue] = useState({
    ...(!isMember && { "first-name": "", "last-name": "" }),
    email: "",

    //required step for form validation
    //coursePricing != priceLevels => set amount to session.activityfee
    ...(IsFree
      ? { amount: "free" }
      : Course
      ? {
          amount: "",
          "payment-method": pinpaymentDetails.usePinPayments ? "" : "In Person",
        }
      : { amount: Session["activity-fee"], "payment-method": "" }),
  });

  const reqFormChangeHandler = useCallback(({ name, value }) => {
    setRequiredFormValue((prevValue) => ({ ...prevValue, [name]: value }));
    setFormError((prev) => ({ ...prev, [name]: null }));
  }, []);

  const history = useHistory();
  const submitHandler = async () => {
    setIsLoading(true);
    const isValid = !doesFormHasError(requiredFormValue, "email", setFormError);

    console.log(
      "%c Is Form Valid => ",
      "background: #990012; color: #FCF7F4; font-weight:normal; font-family:Fira Code;",
      isValid
    );

    if (!isValid) {
      return setIsLoading(false);
    }

    //if no form error ------

    // post request
    try {
      const res = await registerMember({
        formData: { ...formValue, ...requiredFormValue },
        instanceId: instance_identifier,
        ...(isMember && { memberForm: true }),
      });

      console.log(
        "%c User Registration Response => ",
        "background: #068282; color: #95DBE5; font-weight:bold; font-family:Fira Code;",
        res
      );

      if (res && res.formError) {
        setIsLoading(false);
        return setFormError({
          ...formError,
          email: res.formError.replace("Register", "Book"),
        });
      }

      if (res && res.proceed) {
        const { hasDuplicates } = await checkForDuplicateTransactions({
          memberId: res.memberId,
          sessionId: Session.id,
          instanceIdentifier: instance_identifier,
        });

        if (hasDuplicates) {
          return setShowDuplicateAlert(true);
        } else {
          setShowDuplicateAlert(false);
        }

        // make a transaction with the obtained memberId
        if (IsFree) {
          // make a transaction with 0 amount
          const transaction = await createTransaction({
            instanceId: instance_identifier,
            formData: {
              ...formValue,
              ...requiredFormValue,
              amount: 0,
              memberId: res.memberId,
              "transaction-type": "Pay as you go",
              entry: Session,
              type: location.pathname.includes("one-off-session")
                ? "one-off-session"
                : "session",
            },
          });

          if (transaction) {
            history.push({
              pathname: "/success",
              state: {
                entry: Session,
                email: requiredFormValue.email,
                instance_identifier,
              },
            });
          } else {
            history.push({
              pathname: "/error",
              state: {
                message: "Error creating Transaction",
              },
            });
          }
        } else {
          // if payment method is credit card, then redirect to pinpayments
          if (requiredFormValue["payment-method"] === "Credit Card") {
            console.log(
              "%c User directed to Payment",
              "background: #068282; color: #95DBE5; font-weight:bold; font-family:Fira Code;"
            );
            history.push({
              pathname: "/payment",
              state: {
                amount: requiredFormValue.amount,
                burrowedFormValue: {
                  ...requiredFormValue,
                  "email-address": requiredFormValue.email,
                },
                entry: Session,
                instance_identifier,
                type: location.pathname.includes("one-off-session")
                  ? "one-off-session"
                  : "session",
                memberId: res.memberId,
                pinpaymentDetails,
              },
            });
          } else {
            // else no pinpayment redirection
            const transaction = await createTransaction({
              instanceId: instance_identifier,
              formData: {
                ...formValue,
                ...requiredFormValue,
                memberId: res.memberId,
                "transaction-type": "Pay as you go",
                entry: Session,
                type: location.pathname.includes("one-off-session")
                  ? "one-off-session"
                  : "session",
              },
            });

            if (transaction) {
              history.push({
                pathname: "/success",
                state: {
                  entry: Session,
                  email: requiredFormValue.email,
                  instance_identifier,
                },
              });
            } else {
              history.push({
                pathname: "/error",
                state: {
                  message: "Error creating Transaction",
                },
              });
            }
          }
        }
      } else {
        history.push({
          pathname: "/error",
        });
      }
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Tile className={"register-form"}>
      <TileHeader>REGISTER</TileHeader>
      <TileBody className={"register-form-container"}>
        {!isMember && (
          <>
            <div>
              <Label
                label="First Name"
                mandatory
                name="first-name"
                style={{
                  marginBottom: "0.25rem",
                }}
              />
              <TextField
                name="first-name"
                placeholder={"First name"}
                value={requiredFormValue["first-name"]}
                onChange={reqFormChangeHandler}
                error={formError["first-name"]}
                required
              />
            </div>

            <div>
              <Label
                label="Last Name"
                mandatory
                name="last-name"
                style={{
                  marginBottom: "0.25rem",
                }}
              />
              <TextField
                name="last-name"
                placeholder={"Last name"}
                value={requiredFormValue["last-name"]}
                onChange={reqFormChangeHandler}
                error={formError["last-name"]}
                required
              />
            </div>

            <div>
              <Label
                label="Phone number"
                name="phone"
                style={{
                  marginBottom: "0.25rem",
                }}
              />
              <PhoneField
                placeholder="+61-"
                name="phone"
                value={formValue.phone}
                onChange={formChangeHandler}
                required
              />
            </div>
          </>
        )}

        <div>
          <Label
            label="Email"
            mandatory
            name="email"
            style={{
              marginBottom: "0.25rem",
            }}
          />
          <TextField
            name="email"
            placeholder={"abc@mail.com"}
            value={requiredFormValue.email}
            onChange={reqFormChangeHandler}
            error={formError.email}
            required
          />
          <ErrorText
            error={formError.email}
            errorText={formError.email}
            style={{
              marginTop: "0.25rem",
            }}
          />
        </div>

        {IsFree ? null : Course ? (
          <>
            <div className="payment-field-wrapper">
              <Label label="Payment type" name="amount" mandatory />
              <DropdownField
                name="amount"
                value={requiredFormValue.amount}
                onChange={reqFormChangeHandler}
                options={coursePricing
                  .filter((price) => price["session-amount"] !== "")
                  .map((price) => ({
                    label: price["price-level"],
                    value: `${price["session-amount"]}`,
                    required: true,
                  }))}
                orientation={"vertical"}
                placeholder={"Select option"}
                error={formError.amount}
              />
              <ErrorText
                error={formError.amount}
                errorText="Please select a payment type"
                style={{
                  marginTop: "0.25rem",
                }}
              />
            </div>

            <div>
              <Label
                label="Payment method"
                name="payment-method"
                style={{
                  marginBottom: "0.25rem",
                }}
                mandatory
              />
              {pinpaymentDetails.usePinPayments ? (
                <DropdownField
                  name="payment-method"
                  value={requiredFormValue["payment-method"]}
                  onChange={reqFormChangeHandler}
                  options={["Credit Card", "In Person"].map((pay) => ({
                    label: pay,
                    value: pay,
                    required: true,
                  }))}
                  orientation={"vertical"}
                  placeholder={"Select option"}
                  error={formError["payment-method"]}
                />
              ) : (
                <TextDisplay value={requiredFormValue["payment-method"]} />
              )}
              <ErrorText
                error={formError["payment-method"]}
                errorText="Please select a payment method"
                style={{
                  marginTop: "0.25rem",
                }}
              />
            </div>

            <div className="cost-field-wrapper">
              <Label label="Cost" name="Cost" />
              <TextDisplay
                value={
                  requiredFormValue.amount && `$${requiredFormValue.amount}`
                }
                noValueText={"Please select a payment type"}
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <Label
                label="Payment method"
                name="payment-method"
                style={{
                  marginBottom: "0.25rem",
                }}
                mandatory
              />
              <DropdownField
                name="payment-method"
                value={requiredFormValue["payment-method"]}
                onChange={reqFormChangeHandler}
                options={["Credit Card", "In Person"].map((pay) => ({
                  label: pay,
                  value: pay,
                  required: true,
                }))}
                orientation={"vertical"}
                placeholder={"Select option"}
                error={formError["payment-method"]}
              />
              <ErrorText
                error={formError["payment-method"]}
                errorText="Please select a payment method"
                style={{
                  marginTop: "0.25rem",
                }}
              />
            </div>

            <div className="payment-field-wrapper">
              <Label label="Cost" name="Cost" />
              <TextDisplay value={`$${Session["activity-fee"]}`} disabled />
            </div>
          </>
        )}

        {(Session["privacy-policy-url"] ||
          (Course && Course["privacy-policy-url"])) && (
          <span className="privacy-policy-container">
            By submitting your data, you agree to our&nbsp;
            <a
              href={
                Session["privacy-policy-url"] ||
                (Course && Course["privacy-policy-url"])
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
              <BiLinkExternal
                style={{ marginLeft: "4px", verticalAlign: "middle" }}
              />
            </a>
          </span>
        )}

        <Alert show={showDuplicateAlert} variant="danger">
          <AlertBody>
            You already have a booking for this session. Please choose another
            session.
          </AlertBody>
        </Alert>

        <div className="register-form-button-container">
          <span style={{ color: "#383e4b" }}>
            {!isMember ? "Already a member?" : "Not a member?"}&nbsp;
            <span
              className="register-member-link"
              style={{ color: theme.primaryColor }}
              onClick={() => setIsMember((prev) => !prev)}
            >
              {!isMember ? "Book here" : "Register here"}
            </span>
          </span>

          <Button
            size={"md"}
            style={{ marginLeft: "auto" }}
            onClick={submitHandler}
            disabled={isLoading}
          >
            Submit
          </Button>
        </div>
      </TileBody>
    </Tile>
  );
};

export default RegisterForm;
