import { useState } from "react";
import FilterCategory from "./FilterCategory";
import MobileFilter from "./MobileFilter";
import "../../styles/SideBar.css";

const SideNav = () => {
  const [radioField, setRadioField] = useState("");
  return (
    <>
      <aside className="home-side-bar">
        <FilterCategory
          Mobile={false}
          radioField={radioField}
          setRadioField={setRadioField}
        />
      </aside>

      <div className="mobile-filter-layout">
        <MobileFilter radioField={radioField} setRadioField={setRadioField} />
      </div>
    </>
  );
};

export default SideNav;
