import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { format, isBefore, parseISO } from "date-fns";
import { motion } from "framer-motion";
import {
  Tile,
  Label,
  TextField,
  PhoneField,
  DateField,
  DropdownField,
  Button,
  TileHeader,
  TileBody,
  TextDisplay,
} from "@clevero/components";
import countryList from "react-select-country-list";

import { IoChevronBackCircle } from "react-icons/io5";
import doesFormHasError from "../../Utils/doesFormHasError";
import PinPayments from "../../Utils/PinPayments";
import "../../styles/PaymentPage.css";

import { createCharge, getIP, createTransaction } from "../../api/axios";

const DEFAULT_BTN_STATE = {
  isDisabled: false,
  label: "Pay Now",
};

const PaymentPage = () => {
  const countries = useMemo(() => countryList().getLabels(), []);
  const history = useHistory();
  const location = useLocation();
  const {
    instance_identifier,
    memberId,
    type,
    entry,
    amount,
    burrowedFormValue,
    pinpaymentDetails: { surcharge, surchargeConfig },
  } = location.state;

  let Amount = amount;
  Amount = +`${Amount}`.replaceAll("$", "");

  const [formError, setFormError] = useState({});
  const [requiredFormValue, setRequiredFormValue] = useState({
    description: location.state.entry.name,
    "first-name": "",
    "last-name": "",
    "card-number": "",
    cvc: "",
    email: location.state.burrowedFormValue["email-address"],
    "address-line": "",
    city: "",
    state: "",
    "postal-code": "",
    country: "Australia",
  });

  const reqFormChangeHandler = useCallback(({ name, value }) => {
    setRequiredFormValue((prevValue) => ({ ...prevValue, [name]: value }));
    setFormError((prev) => ({ ...prev, [name]: null }));
  }, []);

  const [expiryDate, setExpiryDate] = useState({ value: "", error: null });
  const onExpiryDateChange = ({ value }) => {
    //check is expiry date is in the past
    setExpiryDate({
      value: value,
      error: isBefore(parseISO(value), new Date()) || !value,
    });
  };

  const [btnState, setBtnState] = useState(DEFAULT_BTN_STATE);

  //for pay button disability check
  useEffect(() => {
    const isEmpty = !Object.values(requiredFormValue).every(
      (value) => value !== " " && value !== ""
    );
    setBtnState({ isDisabled: isEmpty || expiryDate.error, label: "Pay Now" });
  }, [requiredFormValue, expiryDate]);

  const submitHandler = async () => {
    if (btnState.isDisabled) {
      return;
    }
    setBtnState({ isDisabled: true, label: "Please Wait..." });

    console.log("Payment Mode => ", process.env.REACT_APP_PIN_PAYMENT_MODE);

    let isValid = !doesFormHasError(requiredFormValue, "email", setFormError);

    if (!expiryDate.value) {
      isValid = false;
      setExpiryDate((prev) => ({ ...prev, error: true }));
    }

    //card number length check
    if (
      requiredFormValue["card-number"] &&
      requiredFormValue["card-number"].replaceAll(" ", "").length !== 16
    ) {
      isValid = false;
      setFormError((prev) => ({ ...prev, "card-number": true }));
    }

    //CVC length check
    if (
      requiredFormValue["cvc"] &&
      requiredFormValue["cvc"].replaceAll(" ", "").length !== 3
    ) {
      isValid = false;
      setFormError((prev) => ({ ...prev, cvc: true }));
    }

    console.log(
      "%c Is form valid => ",
      "background: #990012; color: #FCF7F4; font-weight:normal; font-family:Fira Code;",
      isValid
    );

    if (!isValid) {
      return setBtnState(DEFAULT_BTN_STATE);
    }

    //if no form error ------
    const IpAddress = await getIP();
    const paymentInfo = {
      amount: Amount, //avoid user control
      currency: "AUD",
      description: location.state.entry.name, //avoid user control
      email: location.state.burrowedFormValue["email-address"], //avoid user control
      ip_address: IpAddress || "203.192.1.172",
      cardInfo: {
        number: requiredFormValue["card-number"],
        expiry_month: format(new Date(expiryDate.value), "MM"),
        expiry_year: format(new Date(expiryDate.value), "yyyy"),
        cvc: requiredFormValue.cvc,
        name: `${requiredFormValue["first-name"]} ${requiredFormValue["last-name"]}`,
        address_line1: requiredFormValue["address-line"],
        address_city: requiredFormValue.city,
        address_postcode: requiredFormValue["postal-code"],
        address_state: requiredFormValue.state,
        address_country: requiredFormValue.country,
      },
      testOrLive: process.env.REACT_APP_PIN_PAYMENT_MODE,
    };

    // get the charge token
    const chargeRes = await createCharge({
      paymentInfo: paymentInfo,
      instanceId: location.state["instance_identifier"],
      addSurcharge: surcharge || surcharge === "true",
      surchargePricing:
        (surcharge || surcharge === "true") && surchargeConfig
          ? JSON.parse(surchargeConfig)
          : {},
      domesticCountry: "AU",
    });

    if (!chargeRes) {
      history.push({
        pathname: "/error",
        state: { message: "Error creating charge", allowBack: true },
      });
      return;
    }

    // create transaction after creating pin payment charge
    const transaction = await createTransaction({
      instanceId: instance_identifier,
      formData: {
        "transaction-type":
          type === "course"
            ? "Term/Course Booking"
            : type === "session" || type === "one-off-session"
            ? "Pay as you go"
            : "",
        memberId,
        amount: +amount,
        entry,
        "payment-method": burrowedFormValue["payment-method"] || "Credit Card",
        "payment-reference": chargeRes,
        type,

        // if trans type is Pay as you go, set Pay as you go types to Course/Program or One off activity depending on the "type"
        // for one off, add activity and session
        // for session of program, add program , activity and session
        // for course or program, add course/program and activity in Term/Course Booking Trans Type
      },
    });

    if (!transaction) {
      history.push({
        pathname: "/error",
        state: {
          message: "Error creating Transaction",
          allowBack: true,
        },
      });
      return;
    }

    history.push({
      pathname: "/success",
      state: { instance_identifier: location.state["instance_identifier"] },
    });
  };

  const payFormVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
    exit: { y: "-100vh", opacity: 0 },
  };

  const goBack = () => {
    history.goBack(-1);
  };

  return (
    <motion.div
      variants={payFormVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="payment-page">
        <div className="back-btn" onClick={goBack}>
          <IoChevronBackCircle size="1.25rem" />
          Back
        </div>

        <Tile className="payment-container">
          <TileHeader>Make Payment</TileHeader>

          <TileBody>
            <div className="body-wrapper">
              <div className="field-wrapper">
                <Label
                  label={
                    <div>
                      Amount&nbsp;
                      {surcharge ? (
                        <span className="surcharge-info">
                          (surcharge is applicable, not shown)
                        </span>
                      ) : null}
                    </div>
                  }
                  name="amount"
                />
                <TextDisplay
                  name="amount"
                  value={"$" + Amount}
                  noValueText="Amount not provided"
                />
              </div>

              <div className="field-wrapper">
                <Label label="Description" name="description" />
                <TextDisplay
                  name="description"
                  value={requiredFormValue.description}
                  noValueText="Description not provided"
                />
              </div>

              <div className="field-wrapper">
                <Label label="First name" name="first-name" />
                <TextField
                  name="first-name"
                  onChange={reqFormChangeHandler}
                  value={requiredFormValue["first-name"]}
                  error={formError["first-name"]}
                  required
                />
              </div>

              <div className="field-wrapper">
                <Label label="Last name" name="last-name" />
                <TextField
                  name="last-name"
                  onChange={reqFormChangeHandler}
                  value={requiredFormValue["last-name"]}
                  error={formError["last-name"]}
                  required
                />
              </div>

              <div className="field-wrapper">
                <Label label="Email" name="email" />
                <TextDisplay
                  name="email"
                  value={requiredFormValue.email}
                  noValueText={"Email not provided"}
                />
              </div>

              <div className="field-wrapper">
                <Label label="Card Number" name="card-number" />
                <PhoneField
                  name="card-number"
                  onChange={reqFormChangeHandler}
                  value={requiredFormValue["card-number"]}
                  error={formError["card-number"]}
                  required
                />
              </div>

              <div className="field-wrapper" style={{ flexBasis: "45%" }}>
                <Label label="Expiry date" name="expiryDate" />
                <DateField
                  className="date-field"
                  name="expiryDate"
                  placeholder="MM/YY"
                  onChange={onExpiryDateChange}
                  value={expiryDate.value}
                  dateFormat="MM/yy"
                  minDate={format(new Date(), "yyyy-MM-dd")}
                  error={expiryDate.error}
                  required
                />
              </div>

              <div className="field-wrapper" style={{ flexBasis: "45%" }}>
                <Label label="CVC" name="cvc" />
                <PhoneField
                  name="cvc"
                  onChange={reqFormChangeHandler}
                  value={requiredFormValue.cvc}
                  error={formError.cvc}
                  maxLength={3}
                  required
                />
              </div>

              <div className="field-wrapper">
                <Label label="Country" name="country" />
                <DropdownField
                  name="country"
                  onChange={reqFormChangeHandler}
                  options={countries.map((country) => ({
                    label: country,
                    value: country,
                  }))}
                  placeholder={"Select option"}
                  value={requiredFormValue.country}
                  error={formError.country}
                  disabled
                />
              </div>

              <div className="field-wrapper">
                <Label label="Address Line" name="address-line" />
                <TextField
                  name="address-line"
                  value={requiredFormValue["address-line"]}
                  onChange={reqFormChangeHandler}
                  error={formError["address-line"]}
                  required
                />
              </div>

              <div
                className="field-wrapper"
                style={{ flexBasis: "30%", minWidth: "10rem" }}
              >
                <Label label="City" name="city" />
                <TextField
                  name="city"
                  onChange={reqFormChangeHandler}
                  value={requiredFormValue.city}
                  error={formError.city}
                  required
                />
              </div>

              <div
                className="field-wrapper"
                style={{ flexBasis: "30%", minWidth: "10rem" }}
              >
                <Label label="State" name="state" />
                <TextField
                  name="state"
                  onChange={reqFormChangeHandler}
                  value={requiredFormValue.state}
                  error={formError.state}
                  required
                />
              </div>

              <div
                className="field-wrapper"
                style={{ flexBasis: "30%", minWidth: "10rem" }}
              >
                <Label label="Postal Code" name="postal-code" />
                <TextField
                  name="postal-code"
                  onChange={reqFormChangeHandler}
                  value={requiredFormValue["postal-code"]}
                  error={formError["postal-code"]}
                  required
                />
              </div>

              <div className="btn-wrapper">
                <Button
                  size="lg"
                  disabled={btnState.isDisabled}
                  onClick={submitHandler}
                  style={{ float: "right" }}
                >
                  {btnState.label}
                </Button>
              </div>
            </div>
          </TileBody>
        </Tile>

        <PinPayments />
      </div>
    </motion.div>
  );
};

export default PaymentPage;
